import $ from 'jquery';
import AjaxLookup from './AjaxLookup';
import AutoSuggest from './AutoSuggest';
import Form from './Form';
import Forms from './Forms';
import Field from './Field';
import SelectionListOption from './SelectionListOption';
import getSelect2CustomOptionClass from './Select2CustomOption';

export let currentUrl = new URL(window.location);
export let forms = new Forms();
export { AjaxLookup, AutoSuggest, Form, SelectionListOption };

let $document = $(document),
    $body = $("body");

// Remove spinner (immediately)
let _removeSpinner = ew.removeSpinner;

// Remove spinner
export function removeSpinner() {
    var timer = $body.data("_spinner");
    if (timer)
        timer.cancel();
    timer = $.later(500, null, function() {
        if ($document.data("_ajax") !== true) { // Ajax not running
            _removeSpinner();
            $("form.ew-form").each(function() {
                var frm = forms.get(this.id);
                if (frm) {
                    frm.focus();
                    return false;
                }
            });
        }
    });
    $body.data("_spinner", timer);
}

// Create select2
export function createSelect(options) {
    if (options.selectId.includes("$rowindex$"))
        return;
    $.fn.select2.amd.require(
        ["select2/utils", "select2/results", "select2/dropdown/infiniteScroll", "select2/dropdown/hidePlaceholder", "select2/dropdown/selectOnClose"],
        (function(opts) {
            return function (Utils, ResultsList, InfiniteScroll, HidePlaceholder, SelectOnClose) {
                let options = Object.assign({}, ew.selectOptions, opts);
                if (options.resultsAdapter == null) {
                    options.resultsAdapter = ResultsList;
                    if (options.dropdown && options.columns && options.customOption) {
                        options.resultsAdapter = Utils.Decorate(
                            options.resultsAdapter,
                            getSelect2CustomOptionClass(Utils)
                        );
                        if (options.iconClass && options.multiple && !options.templateResult) {
                            options.templateResult = (result) => '<label class="' + options.iconClass + ' ew-dropdown-label">' + result.text + '</label>';
                        }
                    }
                    if (options.ajax != null) {
                        options.resultsAdapter = Utils.Decorate(
                            options.resultsAdapter,
                            InfiniteScroll
                        );
                    }
                    if (options.placeholder != null) {
                        options.resultsAdapter = Utils.Decorate(
                            options.resultsAdapter,
                            HidePlaceholder
                        );
                    }
                    if (options.selectOnClose) {
                        options.resultsAdapter = Utils.Decorate(
                            options.resultsAdapter,
                            SelectOnClose
                        );
                    }
                }
                if ($.isObject(options.ajax)) {
                    let lookup = new ew.AjaxLookup(options.ajax)
                    options.ajax = {
                        url: (params) => {
                            let start = params.page ? (params.page - 1) * (settings.limit || ew.AUTO_SUGGEST_MAX_ENTRIES) : -1;
                            return lookup.getUrl(params.term, start);
                        },
                        type: "POST",
                        dataType: "json",
                        data: lookup.generateRequest.bind(lookup),
                        delay: options.debounce,
                        processResults: (data) => {
                            return {
                                results: lookup.transform(data).map(item => {
                                    return {
                                        id: item.lf,
                                        text: lookup.formatResult({ lf: item.lf, df: item.df, df2: item.df2, df3: item.df3, df4: item.df4 })
                                    };
                                }),
                                pagination: {
                                    more: data.length < lookup.recordCount
                                }
                            }
                        }
                    };
                }
                $("select[data-select2-id='" + options.selectId + "']").select2(options);
                if (options.multiple && options.minimumResultsForSearch === Infinity) {
                    $("select[id='" + options.name + "']").on("select2:opening select2:closing", function(event) {
                        $(this).parent().find(".select2-search__field").prop("disabled", true);
                    });
                }
            };
        })(options)
    );
}

// Init icon tooltip
export function initIcons(e) {
    var el = (e && e.target) ? e.target : document;
    $(el).find(".ew-icon").closest("a, button").add(".ew-tooltip").tooltip({
        container: "body",
        trigger: (ew.IS_MOBILE) ? "manual" : "hover",
        placement: "bottom",
        sanitizeFn: ew.sanitizeFn
    });
}

// Init password options
export function initPasswordOptions(e) {
    var el = (e && e.target) ? e.target : document;
    if ($.fn.pStrength && typeof ew.MIN_PASSWORD_STRENGTH != "undefined") {
        $(el).find(".ew-password-strength").each(function() {
            var $this = $(this);
            if (!$this.data("pStrength"))
                $this.pStrength({
                    "changeBackground": false,
                    "backgrounds": [],
                    "passwordValidFrom": ew.MIN_PASSWORD_STRENGTH,
                    "onPasswordStrengthChanged": function(strength, percentage) {
                        var $this = $(this), $pst = $("[id='" + $this.attr("data-password-strength") + "']"), // Do not use #
                            $pb = $pst.find(".progress-bar");
                        $pst.width($this.outerWidth());
                        if ($this.val() && !ew.isMaskedPassword(this)) {
                            var pct = percentage + "%";
                            if (percentage < 25) {
                                $pb.addClass("bg-danger").removeClass("bg-warning bg-info bg-success");
                            } else if (percentage < 50) {
                                $pb.addClass("bg-warning").removeClass("bg-danger bg-info bg-success");
                            } else if (percentage < 75) {
                                $pb.addClass("bg-info").removeClass("bg-danger bg-warning bg-success");
                            } else {
                                $pb.addClass("bg-success").removeClass("bg-danger bg-warning bg-info");
                            }
                            $pb.css("width", pct);
                            if (percentage > 25)
                                pct = ew.language.phrase("PasswordStrength").replace("%p", pct);
                            $pb.html(pct);
                            $pst.removeClass("d-none").show();
                            $this.data("validated", percentage >= ew.MIN_PASSWORD_STRENGTH);
                        } else {
                            $pst.addClass("d-none").hide();
                            $this.data("validated", null);
                        }
                    }
                });
        });
    }
    if ($.fn.pGenerator) {
        $(el).find(".ew-password-generator").each(function() {
            var $this = $(this);
            if (!$this.data("pGenerator"))
                $this.pGenerator({
                    "passwordLength": ew.GENERATE_PASSWORD_LENGTH,
                    "uppercase": ew.GENERATE_PASSWORD_UPPERCASE,
                    "lowercase": ew.GENERATE_PASSWORD_LOWERCASE,
                    "numbers": ew.GENERATE_PASSWORD_NUMBER,
                    "specialChars": ew.GENERATE_PASSWORD_SPECIALCHARS,
                    "onPasswordGenerated": function(pwd) {
                        var $this = $(this);
                        $("#" + $this.attr("data-password-field")).val(pwd).trigger("change").trigger("focus").triggerHandler("click"); // Trigger click to remove "is-invalid" class (Do not use $this.data)
                        $("#" + $this.attr("data-password-confirm")).val(pwd);
                        $("#" + $this.attr("data-password-strength")).addClass("d-none").hide();
                    }
                });
        });
    }
}

/**
 * Get API action URL
 * @param {string|string[]} action Route as string or array, e.g. "foo", ["foo", "1"]
 * @param {string|string[]|object} query Search params, e.g. "foo=1&bar=2", [["foo", "1"], ["bar", "2"]], {"foo": "1", "bar": "2"}
 */
export function getApiUrl(action, query) {
    var url = ew.PATH_BASE + ew.API_URL,
        params = new URLSearchParams(query),
        qs = params.toString();
    if ($.isString(action)) { // Route as string
        url += action ? action : "";
    } else if (Array.isArray(action)) { // Route as array
        var route = action.map(function(v) {
            return encodeURIComponent(v);
        }).join("/");
        url += route ? route : "";
    }
    return url + (qs ? "?" + qs : "");
}

// Sanitize URL
export function sanitizeUrl(url) {
    var ar = url.split("?"),
        search = ar[1];
    if (search) {
        var searchParams = new URLSearchParams(search);
        searchParams.forEach((value, key) => searchParams.set(key, ew.sanitize(value)));
        search = searchParams.toString();
    }
    return ar[0] + (search ? "?" + search : "");
}

// Set session timer
export function setSessionTimer() {
    var timeoutTime, timer, keepAliveTimer, counter,
        useKeepAlive = (ew.SESSION_KEEP_ALIVE_INTERVAL > 0 || ew.IS_LOGGEDIN && ew.IS_AUTOLOGIN);
    // Keep alive
    var keepAlive = () => {
        $.get(getApiUrl(ew.API_SESSION_ACTION), { "rnd": random() }, (token) => {
            if (token && $.isObject(token)) { // PHP
                ew.TOKEN_NAME = token[ew.TOKEN_NAME_KEY];
                ew.ANTIFORGERY_TOKEN = token[ew.ANTIFORGERY_TOKEN_KEY];
                if (token["JWT"])
                    ew.API_JWT_TOKEN = token["JWT"];
            }
        });
    };
    // Reset timer
    var resetTimer = () => {
        counter = ew.SESSION_TIMEOUT_COUNTDOWN;
        timeoutTime = ew.SESSION_TIMEOUT - ew.SESSION_TIMEOUT_COUNTDOWN;
        if (timeoutTime < 0) { // Timeout now
            timeoutTime = 0;
            counter = ew.SESSION_TIMEOUT;
        }
        if (timer)
            timer.cancel(); // Clear timer
    };
    // Timeout
    var timeout = () => {
        if (keepAliveTimer)
            keepAliveTimer.cancel(); // Stop keep alive
        if (counter > 0) {
            let timerInterval;
            let message = '<p class="text-danger">' + ew.language.phrase("SessionWillExpire") + '</p>';
            if (message.includes("%m") && message.includes("%s")) {
                message = message.replace("%m", '<span class="ew-session-counter-minute">' + Math.floor(counter / 60) + '</span>')
                message = message.replace("%s", '<span class="ew-session-counter-second">' + (counter % 60) + '</span>')
            } else if (message.includes("%s")) {
                message = message.replace("%s", '<span class="ew-session-counter-second">' + counter + '</span>');
            }
            Swal.fire({
                ...ew.sweetAlertSettings,
                html: message,
                showConfirmButton: true,
                confirmButtonText: ew.language.phrase("OKBtn"),
                timer: counter * 1000,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                onBeforeOpen: () => {
                    timerInterval = setInterval(() => {
                        let content = Swal.getContent(),
                            min = content.querySelector(".ew-session-counter-minute"),
                            sec = content.querySelector(".ew-session-counter-second"),
                            timeleft = Math.round(Swal.getTimerLeft() / 1000);
                        if (min && sec) {
                            min.textContent = Math.floor(timeleft / 60);
                            sec.textContent = timeleft % 60;
                        } else if (sec) {
                            sec.textContent = timeleft;
                        }
                    }, 1000)
                },
                onClose: () => {
                    clearInterval(timerInterval);
                }
            }).then((result) => {
                if (result.value) { // OK button pressed
                    keepAlive();
                    if (!useKeepAlive && ew.SESSION_TIMEOUT > 0)
                        setTimer();
                } else if (result.dismiss === Swal.DismissReason.timer) { // Timeout
                    resetTimer();
                    window.location = sanitizeUrl(ew.TIMEOUT_URL + "?expired=1");
                }
            });
        }
    };
    // Set timer
    var setTimer = () => {
        resetTimer(); // Reset timer first
        timer = $.later(timeoutTime * 1000, null, timeout);
    };
    if (useKeepAlive) { // Keep alive
        var keepAliveInterval = (ew.SESSION_KEEP_ALIVE_INTERVAL > 0) ? ew.SESSION_KEEP_ALIVE_INTERVAL : ew.SESSION_TIMEOUT - ew.SESSION_TIMEOUT_COUNTDOWN;
        if (keepAliveInterval <= 0)
            keepAliveInterval = 60;
        keepAliveTimer = $.later(keepAliveInterval * 1000, null, keepAlive, null, true); // Periodic
    } else {
        if (ew.SESSION_TIMEOUT > 0) // Set session timeout
            setTimer();
    }
}

// Init export links
export function initExportLinks(e) {
    var el = (e && e.target) ? e.target : document;
    $(el).find(".ew-export-link[href]:not(.ew-email):not(.ew-print):not(.ew-xml)").on("click", function(e) {
        var href = $(this).attr("href");
        if (href && href != "#")
            fileDownload(href);
        e.preventDefault();
    });
}

// Init multi-select checkboxes
export function initMultiSelectCheckboxes(e) {
    var el = (e && e.target) ? e.target : document,
        $el = $(el),
        $cbs = $el.find("input[type=checkbox].ew-multi-select");
    var _update = function(id) {
        var $els = $cbs.filter("[name^='" + id + "_']"), cnt = $els.length, len = $els.filter(":checked").length;
        $els.closest("form").find("input[type=checkbox]#" + id)
            .prop("checked", len == cnt).prop("indeterminate", len != cnt && len != 0);
    };
    $cbs.on("click", function(e) {
        _update(this.name.split("_")[0]);
    });
    $el.find("input[type=checkbox].ew-priv:not(.ew-multi-select)").each(function() {
        _update(this.id); // Init
    });
}

// Download file
export function fileDownload(href, data) {
    let win = window.parent,
        jq = win.jQuery,
        swal = win.Swal,
        doc = win.document,
        $doc = jq(doc),
        method = data ? "POST" : "GET",
        isHtml = href.includes("export=html");
    return swal.fire({
        ...ew.sweetAlertSettings,
        html: "<p>" + ew.language.phrase("Exporting") + "</p>",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
            swal.showLoading(),
            jq.ajax({
                url: href,
                type: method,
                cache: false,
                data: data || null,
                xhrFields: {
                    responseType: isHtml ? "text" : "blob"
                }
            }).done((data, textStatus, jqXHR) => {
                var url = win.URL.createObjectURL(isHtml ? new Blob([data], { type: "text/html" }) : data),
                    a = doc.createElement("a"),
                    cd = jqXHR.getResponseHeader("Content-Disposition"),
                    m = cd.match(/\bfilename=((['"])(.+)\2|([^;]+))/i);
                a.style.display = "none";
                a.href = url;
                if (m)
                    a.download = m[3] || m[4];
                doc.body.appendChild(a);
                a.click();
                $doc.trigger("export", [{ "type": "done", "url": href, "objectUrl": url }]);
                win.URL.revokeObjectURL(url);
                swal.close();
            }).fail(() => {
                swal.showValidationMessage("<div class='text-danger'><h4>" + ew.language.phrase("FailedToExport") + "</h4>" + response + "</div>");
                $doc.trigger("export", [{ "type": "fail", "url": href }]);
            }).always(() => {
                $doc.trigger("export", [{ "type": "always", "url": href }]);
            });
        }
    });
}

// Lazy load images
export function lazyLoad(e) {
    if (!ew.LAZY_LOAD)
        return;
    var el = (e && e.target) ? e.target : document;
    $(el).find("img.ew-lazy").each(function () {
        this.src = this.dataset.src;
    });
    $document.trigger("lazyload"); // All images loaded
}

// Update select2 dropdown position
export function updateDropdownPosition() {
    var select = $(".select2-container--open").prev(".ew-select").data("select2");
    if (select) {
        select.dropdown._positionDropdown();
        select.dropdown._resizeDropdown();
    }
}

// Colorboxes
export function initLightboxes(e) {
    if (!ew.USE_COLORBOX)
        return;
    var el = (e && e.target) ? e.target : document;
    var settings = Object.assign({}, ew.lightboxSettings, {
        title: ew.language.phrase("LightboxTitle"),
        current: ew.language.phrase("LightboxCurrent"),
        previous: ew.language.phrase("LightboxPrevious"),
        next: ew.language.phrase("LightboxNext"),
        close: ew.language.phrase("LightboxClose"),
        xhrError: ew.language.phrase("LightboxXhrError"),
        imgError: ew.language.phrase("LightboxImgError")
    });
    $(el).find(".ew-lightbox").each(function() {
        var $this = $(this);
        $this.colorbox(Object.assign({ rel: $this.data("rel") }, settings));
    });
}

// PDFObjects
export function initPdfObjects(e) {
    if (!ew.EMBED_PDF)
        return;
    var el = (e && e.target) ? e.target : document,
        options = Object.assign({}, ew.PDFObjectOptions);
    $(el).find(".ew-pdfobject").not(":has(.pdfobject)").each(function() { // Not already embedded
        var $this = $(this), url = $this.data("url"), html = $this.html();
        if (url)
            PDFObject.embed(url, this, Object.assign(options, { fallbackLink: html }));
    });
}

// Tooltips and popovers
export function initTooltips(e) {
    var el = (e && e.target) ? e.target : document, $el = $(el);
    $el.find("input[data-toggle=tooltip],textarea[data-toggle=tooltip],select[data-toggle=tooltip]").each(function() {
        var $this = $(this);
        $this.tooltip(Object.assign({ html: true, placement: "bottom", sanitizeFn: ew.sanitizeFn }, $this.data()));
    });
    $el.find("a.ew-tooltip-link").each(tooltip); // Init tooltips
    $el.find(".ew-tooltip").tooltip({ placement: "bottom", sanitizeFn: ew.sanitizeFn });
    $el.find(".ew-popover").popover({ sanitizeFn: ew.sanitizeFn });
}

// Parse JSON
export function parseJson(data) {
    if ($.isString(data)) {
        try {
            return JSON.parse(data);
        } catch(e) {
            return undefined;
        }
    }
    return data;
}

// Change search operator
export function searchOperatorChanged(el) {
    var $el = $(el),
        $p = $el.closest("[id^=r_], [id^=xsc_]"),
        parm = el.id.substr(2),
        $fld = $p.find(".ew-search-field"),
        $fld2 = $p.find(".ew-search-field2"),
        $y = $fld2.find("[name='y_" + parm + "'], [name='y_" + parm + "[]']"),
        hasY = $y.length,
        $cond = $p.find(".ew-search-cond"),
        hasCond = $cond.length, // Has condition and operator 2
        $and = $p.find(".ew-search-and"),
        $opr = $p.find(".ew-search-operator"),
        opr = $opr.find("[name='z_" + parm + "']").val(),
        $opr2 = $p.find(".ew-search-operator2"),
        opr2 = $opr2.find("[name='w_" + parm + "']").val(),
        isBetween = opr == "BETWEEN", // Can only be operator 1
        isNullOpr = ["IS NULL", "IS NOT NULL"].includes(opr),
        isNullOpr2 = ["IS NULL", "IS NOT NULL"].includes(opr2),
        hideOpr2 = !hasY || isBetween,
        hideX = isNullOpr,
        hideY = !isBetween && (!hasCond || isNullOpr2);
    $cond.toggleClass("d-none", hideOpr2).find(":input").prop("disabled", hideOpr2);
    $and.toggleClass("d-none", !isBetween);
    $opr2.toggleClass("d-none", hideOpr2).find(":input").prop("disabled", hideOpr2);
    $fld.toggleClass("d-none", hideX).find(":input").prop("disabled", hideX);
    $fld2.toggleClass("d-none", hideY).find(":input").prop("disabled", hideY);
}

// Init forms
function initForms(e) {
    let el = (e && e.target) ? e.target : document,
        $el = $(el),
        ids = ew.forms.ids();
    for (let id of ids) {
        if ($el.find("#" + id))
            forms.get(id).init();
    }
}

// Is function
export function isFunction(x) {
    return typeof x === "function";
}

// Prompt/Confirm/Alert
function _prompt(text, cb, input, validator) {
    if (input) { // Prompt
        return Swal.fire({
            ...ew.sweetAlertSettings,
            html: text,
            input: "text",
            confirmButtonText: ew.language.phrase("OKBtn"),
            showCancelButton: isFunction(cb),
            cancelButtonText: ew.language.phrase("CancelBtn"),
            inputValidator: validator || ((value) => {
                if (!value)
                    return ew.language.phrase("EnterValue");
            })
        }).then(result => {
            if (isFunction(cb))
                cb(result.value);
        });
    } else { // Confirm or Alert
        return Swal.fire({
            ...ew.sweetAlertSettings,
            html: "<div>" + text + "</div>",
            confirmButtonText: ew.language.phrase("OKBtn"),
            showCancelButton: isFunction(cb),
            cancelButtonText: ew.language.phrase("CancelBtn"),
        }).then(result => {
            if (isFunction(cb))
                cb(result.value);
        });
    }
}

export { _prompt as prompt };

// Toast
export function toast(options) {
    options = Object.assign({}, ew.toastOptions, options);
    $document.Toasts("create", options);
    var position = options.position,
        $container = $("#toastsContainer" + position[0].toUpperCase() + position.substring(1));
    return $container.children().first();
}

/**
 * Show toast
 *
 * @param {string} msg - Message
 * @param {string} type - CSS class: "muted|primary|success|info|warning|danger"
 */
export function showToast(msg, type) {
    type = type || "danger";
    return toast({
        class: "ew-toast bg-" + type,
        title: ew.language.phrase(type),
        body: msg,
        autohide: (type == "success") ? ew.autoHideSuccessMessage : false, // Autohide for success message
        delay: (type == "success") ? ew.autoHideSuccessMessageDelay: 500
    });
}

// Get form.ew-form or div.ew-form HTML element
export function getForm(el) {
    if (el instanceof Form)
        return el.$element[0];
    var $el = $(el), $f = $el.closest(".ew-form");
    if (!$f[0]) // Element not inside form
        $f = $el.closest(".ew-grid, .ew-multi-column-grid").find(".ew-form").not(".ew-pager-form");
    return $f[0];
}

// Check form data
export function hasFormData(form) {
    var selector = "[name^=x_],[name^=y_],[name^=z_],[name^=w_],[name=psearch]",
        els = $(form).find(selector).filter(":enabled").get();
    for (var i = 0, len = els.length; i < len; i++) {
        var el = els[i];
        if (/^(z|w)_/.test(el.name)) {
            if (/^IS/.test($(el).val()))
                return true;
        } else if (el.type == "checkbox" || el.type == "radio") {
            if (el.checked)
                return true;
        } else if (el.type == "select-one" || el.type == "select-multiple") {
            if (!!$(el).val())
                return true;
        } else if (el.type == "text" || el.type == "hidden" || el.type == "textarea") {
            if (el.value)
                return true;
        }
    }
    return false;
}

// Set search type
export function setSearchType(el, val) {
    var $this = $(el), $form = $this.closest("form"), text = "";
    $form.find("input[name=psearchtype]").val(val || "");
    if (val == "=") {
        text = ew.language.phrase("QuickSearchExactShort");
    } else if (val == "AND") {
        text = ew.language.phrase("QuickSearchAllShort");
    } else if (val == "OR") {
        text = ew.language.phrase("QuickSearchAnyShort");
    } else {
        text = ew.language.phrase("QuickSearchAutoShort");
    }
    $form.find("#searchtype").html(text + ((text) ? "&nbsp;" : ""));
    $this.closest("ul").find("li").removeClass("active");
    $this.closest("li").addClass("active");
    return false;
}

/**
 * Update a dynamic selection list
 *
 * @param {(HTMLElement|HTMLElement[]|string|string[])} obj - Target HTML element(s) or the ID of the element(s)
 * @param {(string[]|array[])} parentId - Parent field element names or data
 * @param {(boolean|null)} async - async(true) or sync(false) or non-Ajax(null)
 * @param {boolean} change - Trigger onchange event
 * @returns
 */
export function updateOptions(obj, parentId, async, change) {
    var f = (this.$element) ? this.$element[0] : (this.form) ? this.form : null; // Get form/div element from this
    if (!f)
        return;
    var frm = (this.htmlForm) ? this : forms.get(f.id); // Get Form object
    if (!frm)
        return;
    if (this.form && $.isUndefined(obj)) // Target unspecified
        obj = forms.get(this).getList(this.name).childFields.slice(); // Clone
    else if ($.isString(obj))
        obj = getElements(obj, f);
    if (!obj || Array.isArray(obj) && obj.length == 0)
        return;
    var self = this, promise = Promise.resolve();
    if (Array.isArray(obj) && $.isString(obj[0])) { // Array of id (onchange/onclick event)
        var els = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            var ar = obj[i].split(" ");
            if (ar.length == 1 && self.form) { // Parent/Child fields in the same table
                var m = getId(self, false).match(/^([xy]\d*_)/);
                if (m)
                    obj[i] = obj[i].replace(/^([xy]\d*_)/, m[1]);
            }
            var el = getElements(obj[i], f), names = [];
            els.push(el);
            if (ar.length == 2 && Array.isArray(el)) { // Check if id is "tblvar fldvar" and multiple inputs
                var $el = $(el);
                $el.each(function() {
                    if (!names.includes(this.name)) {
                        names.push(this.name);
                        var $elf = $el.filter("[name='" + this.name + "']"), typ = $elf.attr("type"),
                            elf = ["radio", "checkbox"].includes(typ) ? $elf.get() : $elf[0];
                        promise = promise.then(_updateOptions.bind(self, elf, parentId, async, change));
                    }
                });
            } else {
                promise = promise.then(_updateOptions.bind(self, el, parentId, async, change));
            }
        }
        obj = els;
        var list = forms.get(self).getList(self.name);
        if (list && Array.isArray(list.autoFillTargetFields) && list.autoFillTargetFields[0]) // AutoFill
            promise = promise.then(autoFill.bind(null, self));
    } else {
        promise = promise.then(_updateOptions.bind(self, obj, parentId, async, change));
    }
    return promise.then(function() {
        $document.trigger("updatedone", [{ source: self, target: obj }]); // Document "updatedone" event fired after all the target elements are updated
    });
}

/**
 * Update a dynamic selection list
 *
 * @param {(HTMLElement|HTMLElement[]} obj - Target HTML element(s) or the ID of the element(s)
 * @param {(string[]|array[])} parentId - Parent field element names or data
 * @param {(boolean|null)} async - async(true) or sync(false) or non-Ajax(null)
 * @param {boolean} change - Trigger onchange event
 * @returns Promise
 */
function _updateOptions(obj, parentId, async, change) {
    var id = getId(obj, false);
    if (!id)
        return;
    var fo = getForm(obj); // Get form/div element from obj
    if (!fo || !fo.id)
        return;
    var frmo = forms.get(fo.id);
    if (!frmo)
        return;
    var self = this,
        args = Array.from(arguments),
        ar = getOptionValues(obj),
        m = id.match(/^([xy])(\d*)_/),
        prefix = m ? m[1] : "",
        rowindex = m ? m[2] : "",
        arp = [],
        list = frmo.getList(id),
        $obj = $(obj).data("updating", true);
    if ($obj.data("hidden")) // Skip data-hidden field, e.g. detail key
        return;
    if ($.isUndefined(parentId)) { // Parent IDs not specified, use default
        parentId = list.parentFields.slice(); // Clone
        if (rowindex != "") {
            for (var i = 0, len = parentId.length; i < len; i++) {
                var arr = parentId[i].split(" ");
                if (arr.length == 1) // Parent field in the same table, add row index
                    parentId[i] = parentId[i].replace(/^x_/, "x" + rowindex + "_");
            }
        }
    }
    if (Array.isArray(parentId) && parentId.length > 0) {
        if (Array.isArray(parentId[0])) { // Array of array => data
            arp = parentId;
        } else if ($.isString(parentId[0])) { // Array of string => Parent IDs
            for (var i = 0, len = parentId.length; i < len; i++)
                arp.push(getOptionValues(parentId[i], fo));
        }
    }
    if (!isAutoSuggest(obj)) // Do not clear Auto-Suggest
        clearOptions(obj);
    var addOpt = function(results) {
        var name = getId(obj);
        results.forEach(function(result) {
            let args = {"data": result, "parents": arp, "valid": true, "name": name, "form": fo};
            $document.trigger("addoption", [args]);
            if (args.valid)
                newOption(obj, result, fo);
        });
        if (obj.list)
            obj.render();
        selectOption(obj, ar);
        if (change !== false) {
            if (!obj.options && obj.length)
                $obj.first().triggerHandler("click");
            else
                $obj.first().trigger("change");
        }
    }
    if ($.isUndefined(async)) // Async not specified, use default
        async = list.ajax;
    var _updateSibling = function() { // Update the y_* element
        if (/s(ea)?rch$/.test(fo.id) && prefix == "x") { // Search form
            args[0] = id.replace(/^x_/, "y_");
            updateOptions.apply(self, args); // args[0] is string, use updateOptions()
        }
    }
    if (!$.isBoolean(async) || Array.isArray(list.lookupOptions) && list.lookupOptions.length > 0) { // Non-Ajax or Options loaded
        var ds = list.lookupOptions;
        addOpt(ds);
        _updateSibling();
        return ds;
    } else { // Ajax
        var name = getId(obj), data = Object.assign({
            page: list.page,
            field: list.field,
            ajax: "updateoption",
            language: ew.LANGUAGE_ID,
            name: name // Name of the target element
        }, getUserParams("#p_" + id, fo)); // Add user parameters
        if (isAutoSuggest(obj) && self.htmlForm) // Auto-Suggest (init form or auto-fill)
            data["v0"] = ar[0] || random(); // Filter by the current value
        else if (isModalLookup(obj)) // Modal-Lookup
            data["v0"] = ar[0] ? (obj.multiple ? ar.join(ew.MULTIPLE_OPTION_SEPARATOR) : ar[0]) : random(); // Filter by the current value
        for (var i = 0, cnt = arp.length; i < cnt; i++) // Filter by parent fields
            data["v" + (i + 1)] = arp[i].join(ew.MULTIPLE_OPTION_SEPARATOR);
        return $.ajax(getApiUrl(ew.API_LOOKUP_ACTION), {
                "type": "POST", "dataType": "json", "data": data, "async": async
            }).done((result) => {
                var ds = result.records || [];
                addOpt(ds);
                _updateSibling();
                $obj.first().trigger("updated", [Object.assign({}, result, { target: obj })]); // Object "updatedone" event fired after the object is updated
                return ds;
            }).always(() => $obj.data("updating", false));
    }
}

// Get user parameters from id
export function getUserParams(id, root) {
    var id = id.replace(/\[\]$/, ""), o = {};
    var root = !$.isString(root) ? root : /^#/.test(root) ? root : "#" + root;
    var $els = (root) ? $(root).find(id) : $(id);
    var val = $els.val();
    if (val) {
        var params = new URLSearchParams(val);
        params.forEach(function(value, key) {
            o[key] = value;
        });
    }
    return o;
}

// Apply client side template to a DIV
export function applyTemplate(divId, tmplId, classId, exportType, data) { // Note: classId = fileName
    var args = {"data": data || {}, "id": divId, "template": tmplId, "class": classId, "export": exportType, "enabled": true};
    $document.trigger("rendertemplate", [args]);
    if (args.enabled) {
        if (document.body.replaceWith) { // Not IE
            var template = document.getElementById(tmplId).content;
            template.querySelectorAll(".ew-slot").forEach(el => {
                var subtmpl = document.getElementById(el.name || el.id);
                if (subtmpl && subtmpl.content) {
                    if (el.dataset.rowspan > 1)
                        Array.prototype.slice.call(subtmpl.content.childNodes).forEach(node => node.rowSpan = el.dataset.rowspan);
                    el.replaceWith(subtmpl.content);
                } else {
                    el.remove();
                }
            });
            if ($.views) {
                var textContent = template.textContent,
                    hasTag = textContent.includes("{{") && textContent.includes("}}");
                if (!hasTag) {
                    var selector = ew.jsRenderAttributes.map(attr => "[" + attr + "*='{{'][" + attr + "*='}}']").join(",");
                    hasTag = template.querySelector(selector);
                }
                if (hasTag) { // Includes JsRender template
                    var scripts = Array.prototype.slice.call(template.querySelectorAll("script")); // Extract scripts
                    scripts.forEach(item => item.remove());
                    var div = document.createElement("div");
                    div.appendChild(template);
                    var html = div.innerHTML, tmpl = $.templates(html);
                    document.getElementById(divId).innerHTML = tmpl.render(args.data, ew.jsRenderHelpers);
                    scripts.forEach(item => document.body.appendChild(item)); // Add scripts
                } else {
                    document.getElementById(divId).appendChild(template);
                }
            } else {
                document.getElementById(divId).appendChild(template);
            }
        } else { // IE
            _alert(ew.language.phrase("UnsupportedBrowser") || "Your browser is not supported by this page.");
        }
    }
    if (exportType && exportType != "print") { // Export custom
        $(function() {
            var $meta = $("meta[http-equiv='Content-Type']"),
                html = "<html><head>",
                $div = $("#" + divId);
            if ($div.children(0).is("div[id^=ct_]")) // Remove first div tag
                $div = $div.children(0);
            if ($meta[0])
                html += "<meta http-equiv='Content-Type' content='" + $meta.attr("content") + "'>";
            if (exportType == "pdf") {
                html += "<link rel='stylesheet' href='" + ew.PDF_STYLESHEET_FILENAME + "'>";
            } else {
                html += "<style>" + $.ajax({async: false, type: "GET", url: ew.PROJECT_STYLESHEET_FILENAME}).responseText + "</style>";
            }
            html += "</" + "head><body>";
            $(".ew-chart-top").each(function() {
                html += $(this).html();
            });
            html += $div.html();
            $(".ew-chart-bottom").each(function() {
                html += $(this).html();
            });
            html += "</body></html>";
            var url = currentPage(),
                data = { "customexport": exportType, "data": html, "filename": args.class };
            data[ew.TOKEN_NAME] = ew.ANTIFORGERY_TOKEN;
            if (exportType == "email") {
                var str = currentUrl.searchParams.toString() + "&" + $.param(data); // Add data
                $.post(url, str, function(result) {
                    showMessage(result);
                });
            } else {
                fileDownload(url, data);
            }
            window.parent.jQuery("body").css("cursor", "default"); // Use window.parent in case in iframe
        });
    }
}

// Toggle group
export function toggleGroup(el) {
    var $el = $(el), $tr = $el.closest("tr"), selector = "tr", level;
    for (var i = 1; i <= 6; i++) {
        var idx = (i == 1) ? "" : "-" + i;
        var data = $tr.data("group" + idx);
        if ($.isValue(data)) {
            level = i;
            if (data != "")
                selector += "[data-group" + idx + "='" + String(data).replace(/'/g, "\\'") + "']";
        }
    }
    if ($el.hasClass("icon-collapse")) { // Hide
        $(selector).slice(1).addClass("ew-rpt-grp-hide-" + level);
        $el.toggleClass("icon-expand icon-collapse");
    } else {
        $(selector).slice(1).removeClass("ew-rpt-grp-hide-" + level);
        $el.toggleClass("icon-expand icon-collapse");
    }
}

// Check if boolean value is true
export function convertToBool(value) {
    return value && ["1", "y", "t", "true"].includes(value.toLowerCase());
}

// Check if element value changed
export function valueChanged(fobj, infix, fld, bool) {
    var nelm = getElements("x" + infix + "_" + fld, fobj);
    var oelm = getElement("o" + infix + "_" + fld, fobj); // Hidden element
    var fnelm = getElement("fn_x" + infix + "_" + fld, fobj); // Hidden element
    if (nelm?.type == "hidden" && !oelm) // For example, detail key
        return false;
    if (!oelm && (!nelm || Array.isArray(nelm) && nelm.length == 0))
        return false;
    var getValue = (obj) => getOptionValues(obj).join();
    if (oelm && nelm) {
        if (bool) {
            if (convertToBool(getValue(oelm)) === convertToBool(getValue(nelm)))
                return false;
        } else {
            var oldvalue = getValue(oelm);
            var newvalue = (fnelm) ? getValue(fnelm) : getValue(nelm);
            if (oldvalue == newvalue)
                return false;
        }
    }
    return true;
}

// Set language
export function setLanguage(el) {
    var $el = $(el),
        val = $el.val() || $el.data("language");
    if (!val)
        return;
    currentUrl.searchParams.set("language", val);
    window.location = sanitizeUrl(currentUrl.toString());
}

/**
 * Submit action
 *
 * @param {Event} e
 * @param {Object} args - Arguments
 * @param {HTMLElement} args.f - HTML form (default is the form of the source element)
 * @param {string} args.url - URL to which the request is sent (default is current page)
 * @param {Object} args.key - Key as object (for single record only)
 * @param {string} args.msg - Confirm message
 * @param {string} args.action - Custom action name
 * @param {string} args.select - "single"|"s" (single record) or "multiple"|"m" (multiple records, default)
 * @param {string} args.method - "ajax"|"a" (Ajax by HTTP POST) or "post"|"p" (HTTP POST, default)
 * @param {Object} args.data - Object of user data that is sent to the server
 * @param {string|callback|Object} success - Function to be called if the request succeeds, or settings for jQuery.ajax() (for Ajax only)
 * @returns
 */
export function submitAction(e, args) {
    var el = e.target || e.srcElement, $el = $(el),
        f = args.f || $el.closest("form")[0] || currentForm, $f = $(f),
        key = args.key, action = args.action, url = args.url || currentPage(),
        msg = args.msg, data = args.data, success = args.success,
        isPost = !args.method || sameText(args.method[0], "p"),
        isMultiple = !args.select && !args.key || args.select && sameText(args.select[0], "m");
    if (isMultiple && !$f[0])
        return false;
    if (isMultiple && !keySelected($f[0])) {
        _prompt("<p class=\"text-danger\">" + ew.language.phrase("NoRecordSelected") + "</p>");
        return false;
    }
    var _success = function(result) {
        showMessage(result);
    };
    var _submit = function() {
        if (isPost) { // Post back
            if (action) // Action
                $("<input>").attr({type: "hidden", name: "useraction", value: action}).appendTo($f);
            if ($.isObject(data)) { // User data
                for (var k in data) {
                    var $input = $f.find("input[type=hidden][name='" + k + "']");
                    if ($input[0])
                        $input.val(data[k]);
                    else
                        $("<input>").attr({type: "hidden", name: k, value: data[k]}).appendTo($f);
                }
            }
            if (!isMultiple && $.isObject(key)) { // Key
                for (var k in key)
                    $("<input>").attr({type: "hidden", name: k, value: key[k]}).appendTo($f);
            }
            $f.prop("action", url).trigger("submit");
            // if (action) // Action
            //     $f.find("input[type=hidden][name=useraction]").remove(); // Remove the "useraction" element
        } else { // Ajax
            data = $.isObject(data) ? $.param(data) : $.isString(data) ? data : ""; // User data
            if (action)
                data += "&useraction=" + action + "&ajax=" + action; // Action
            if (isMultiple) // Multiple records
                data += "&" + $f.find("input[name='key_m[]']:checked").serialize(); // Keys
            else if (key) // Single record
                data += "&" + ($.isObject(key) ? $.param(key) : key); // Key
            if (success && $.isString(success))
                success = window[success];
            if (isFunction(success)) {
                $.post(url, data, success);
            } else if ($.isObject(success)) { // "success" is Ajax settings
                success.data = data;
                success.method = success.method || "POST";
                success.success = success.success || _success;
                $.ajax(url, success);
            } else {
                $.post(url, data, _success);
            }
        }
    };
    if (msg) {
        _prompt(msg, (result) => {
            if (result)
                _submit();
        });
    } else {
        _submit();
    }
    return false;
}

/**
 * Export with selected records and/or Custom Template
 *
 * @param {string} f - Form ID
 * @param {string} url - Form action
 * @param {string} type - Export type
 * @param {boolean} custom - Using Custom Template
 * @param {boolean} sel - Selected records only
 * @param {Object} fobj - email form object
 * @returns false
 */
function _export(f, url, type, custom, sel, fobj) {
    if (!f)
        return false;
    var $f = $(f),
        target = $f.attr("target"),
        action = $f.attr("action"),
        cb = sel && $f.find("input[type=checkbox][name='key_m[]']")[0];
    if (cb && !keySelected(f)) {
        _alert(ew.language.phrase("NoRecordSelected"));
        return false;
    }
    if (custom) { // Use Custom Template
        $("iframe.ew-export").remove();
        if (type == "email")
            url += ("&" + $(fobj).serialize()).replace(/&export=email/i, ""); // Remove duplicate export=email
        if (cb) {
            $("<iframe>").attr("name", "ew-export-frame").addClass("ew-export d-none").appendTo($body);
            try {
                $f.append($("<input type='hidden'>").attr({name: "custom", value: "1"}))
                    .attr({ "action": url, "target": "ew-export-frame" }).find("input[name=exporttype]").val(type).end().trigger("submit");
            } finally { // Reset
                $f.attr({ "target": target || "", "action": action }).find("input[name=custom]").remove();
            }
        } else {
            $("<iframe>").attr({ name: "ew-export-frame", src: url }).addClass("ew-export d-none").appendTo($body);
        }
    } else { // No Custom Template
        $f.find("input[name=exporttype]").val(type);
        if (["xml", "print"].includes(type))
            $f.trigger("submit"); // Submit the form directly
        else
            fileDownload(action, $f.serialize());
    }
    return false;
}

export { _export as export };

/**
 * Remove spaces
 * @param {string} value
 * @returns {string}
 */
export function removeSpaces(value) {
    return (/^(<(p|br)\/?>(&nbsp;)?(<\/p>)?)?$/i.test(value.replace(/\s/g, ""))) ? "" : value;
}

/**
 * Check if hidden text area (HTML editor)
 * @param {HTMLElement|jQuery} el HTML element or jQuery object
 * @returns {boolean}
 */
export function isHiddenTextArea(el) {
    var $el = $(el);
    return $el.is(":hidden") && $el.data("editor");
}

/**
 * Check if modal lookup
 * @param {HTMLElement|jQuery} el HTML element or jQuery object
 * @returns {boolean}
 */
export function isModalLookup(el) {
    var $el = $(el);
    return $el.is(":hidden") && $el.data("lookup");
}

/**
 * Check if hidden textbox (Auto-Suggest)
 * @param {HTMLElement|jQuery} el HTML element or jQuery object
 * @returns {boolean}
 */
export function isAutoSuggest(el) {
    var $el = $(el);
    return $el.is(":hidden") && $el.data("autosuggest");
}

/**
 * Alert
 *
 * @param {string} msg - Message
 * @param {callback} cb - Callback function
 * @param {string} type - CSS class (see https://getbootstrap.com/docs/4.5/utilities/colors/#color)
 */
function _alert(msg, cb, type) {
    return Swal.fire({
        ...ew.sweetAlertSettings,
        html: '<p class="text-' + (type || 'danger') + '">' + msg + '</p>',
        confirmButtonText: ew.language.phrase("OKBtn")
    }).then(result => {
        if (isFunction(cb))
            cb(result.value);
    });
}

export { _alert as alert };

/**
 * Clear error message
 * @param {HTMLElement|HTMLElement[]|jQuery} el HTML element(s) or jQuery
 */
export function clearError(el) {
    if (el.jquery) { // el is jQuery object
        let typ = el.attr("type");
        el = (typ == "checkbox" || typ == "radio") ? el.get() : el[0];
    }
    $(el).closest("[id^=el_], .form-group").find(".invalid-feedback").html("");
}

/**
 * Show error message
 * @param {Form} frm Form object
 * @param {HTMLElement|HTMLElement[]|jQuery} el HTML element(s) or jQuery
 * @param {string} msg Error message
 * @param {boolean} focus Set focus
 */
export function onError(frm, el, msg, focus) {
    if (el.jquery) { // el is jQuery object
        let typ = el.attr("type");
        el = (typ == "checkbox" || typ == "radio") ? el.get() : el[0];
    } else if (el instanceof Field) { // el is Field object
        el = el.element;
    }
    $(el).closest("[id^=el_], .form-group").find(".invalid-feedback").append("<p>" + msg + "</p>");
    if (focus)
        setFocus(el);
    frm?.makeVisible(el);
    return false;
}

/**
 * Set focus
 * @param {HTMLElement|HTMLElement[]} obj HTML element(s)
 */
export function setFocus(obj) {
    if (!obj)
        return;
    var $obj = $(obj);
    if (isHidden($obj))
        return;
    if (isHiddenTextArea(obj)) { // HTML editor
        return $obj.data("editor").focus();
    } else if (isModalLookup(obj)) { // Modal lookup
        return $obj.parent().find(".ew-lookup-text").trigger("focus");
    } else if (!obj.options && obj.length) { // Radio/Checkbox list
        obj = $obj[0];
    } else if (isAutoSuggest(obj)) { // Auto-Suggest
        obj = obj.input;
    }
    $(obj).trigger("focus");
}

/**
 * Set invalid
 * @param {HTMLElement|HTMLElement[]} obj HTML element(s)
 */
export function setInvalid(obj) {
    if (!obj)
        return;
    var $obj = $(obj);
    if (isHidden($obj))
        return;
    if (!obj.options && obj.length) // Radio/Checkbox list
        obj = $obj[0];
    var $p = $obj.closest(".form-group, [id^='el']");
    if (isAutoSuggest(obj)) {
        $p.find(".ew-auto-suggest").addClass("is-invalid").one("click keydown", function() {
            $p.find(".is-invalid").removeClass("is-invalid");
        });
    } else if (isModalLookup(obj)) {
        $p.find(".input-group").addClass("is-invalid").one("click keydown", function() {
            $p.find(".is-invalid").removeClass("is-invalid");
        });
    } else {
        if (obj.type == "checkbox" || obj.type == "radio") {
            $obj.addClass("is-invalid").one("click keydown", function() {
                $p.find(".is-invalid").removeClass("is-invalid");
            });
        } else {
            $obj.addClass("is-invalid").parent().one("click keydown", function() {
                $p.find(".is-invalid").removeClass("is-invalid");
            })
            $obj.closest(".input-group").addClass("is-invalid");
        }
    }
}

// Check if object has value
export function hasValue(obj) {
    return getOptionValues(obj).join("") != "";
}

// Check if object value is a masked password
export function isMaskedPassword(obj) {
    var val = $(obj).val();
    return val && val.match(/^\*+$/);
}

// Get Ctrl key for multiple column sort
export function sort(e, url, type) {
    if (e.shiftKey && !e.ctrlKey)
        url = url.split("?")[0] + "?cmd=resetsort";
    else if (type == 2 && e.ctrlKey)
        url += "&ctrl=1";
    window.location = sanitizeUrl(url);
    return true;
}

// Confirm Delete Message
export function confirmDelete(el) {
    clickDelete(el);
    _prompt(ew.language.phrase("DeleteConfirmMsg"), (result) => {
        (result && el.href) ? window.location = sanitizeUrl(el.href) : clearDelete(el);
    });
    return false;
}

// Check if any key selected // PHP
export function keySelected(f) {
    return $(f).find("input[type=checkbox][name='key_m[]']:checked", f).length > 0;
}

// Select all key
export function selectAllKey(cb) {
    selectAll(cb);
    var tbl = $(cb).closest(".ew-table")[0];
    if (!tbl)
        return;
    $(tbl.tBodies).each(function() {
        $(this.rows).each(function(i, r) {
            var $r = $(r);
            if ($r.is(":not(.ew-template):not(.ew-table-preview-row)")) {
                $r.data({ selected: cb.checked, checked: cb.checked });
                setColor(i, r);
            }
        });
    });
}

// Select all related checkboxes
export function selectAll(cb) {
    if (!cb || !cb.form)
        return;
    $(cb.form.elements).filter("input[type=checkbox][name^=" + cb.name + "_], [type=checkbox][name=" + cb.name + "]").not(cb).not(":disabled").prop("checked", cb.checked);
}

// Update selected checkbox
export function updateSelected(f) {
    return $(f).find("input[type=checkbox][name^=u_]:checked,input:hidden[name^=u_][value=1]").length > 0;
}

// Set row color
export function setColor(index, row) {
    var $row = $(row), $tbl = $row.closest(".ew-table");
    if (!$tbl[0])
        return;
    if ($row.data("selected")) {
        $row.removeClass($tbl.data("rowhighlightclass") || "ew-table-highlight-row")
            .removeClass($tbl.data("roweditclass") || "ew-table-edit-row")
            .addClass($tbl.data("rowselectclass") || "ew-table-select-row");
    } else if ([ew.ROWTYPE_ADD, ew.ROWTYPE_EDIT].includes($row.data("rowtype"))) {
        $row.removeClass($tbl.data("rowselectclass") || "ew-table-select-row")
            .removeClass($tbl.data("rowhighlightclass") || "ew-table-highlight-row")
            .addClass($tbl.data("roweditclass") || "ew-table-edit-row");
    } else {
        $row.removeClass($tbl.data("rowselectclass") || "ew-table-select-row")
            .removeClass($tbl.data("roweditclass") || "ew-table-edit-row")
            .removeClass($tbl.data("rowhighlightclass") || "ew-table-highlight-row");
    }
}

// Clear selected rows color
export function clearSelected(tbl) {
    $(tbl.rows).each(function(i, r) {
        var $r = $(r);
        if (!$r.data("checked") && $r.data("selected")) {
            $r.data("selected", false);
            setColor(i, r);
        }
    });
}

// Clear all row delete status
export function clearDelete(el) {
    var $el = $(el), tbl = $el.closest(".ew-table")[0];
    if (!tbl)
        return;
    var $tr = $el.closest(".ew-table > tbody > tr");
    $tr.siblings("[data-rowindex='" + $tr.data("rowindex") + "']").addBack().each(function(i, r) {
        var $r = $(r);
        $r.data("selected", $r.data("checked"));
    });
}

// Click single delete link
export function clickDelete(el) {
    var $el = $(el), tbl = $el.closest(".ew-table")[0];
    if (!tbl)
        return;
    clearSelected(tbl);
    var $tr = $el.closest(".ew-table > tbody > tr");
    $tr.siblings("[data-rowindex='" + $tr.data("rowindex") + "']").addBack().each(function(i, r) {
        $(r).data("selected", true);
        setColor(i, r);
    });
}

// Click multiple checkbox
export function clickMultiCheckbox(e) {
    var cb = e.target || e.srcElement, $cb = $(cb), tbl = $cb.closest(".ew-table")[0];
    if (!tbl)
        return;
    clearSelected(tbl);
    var $tr = $cb.closest(".ew-table > tbody > tr");
    $tr.siblings("[data-rowindex='" + $tr.data("rowindex") + "']").addBack().each(function(i, r) {
        $(r).data("checked", cb.checked).data("selected", cb.checked).find("input[type=checkbox][name='key_m[]']").each(function() {
            if (this != cb) this.checked = cb.checked;
        });
        setColor(i, r);
    });
    e.stopPropagation();
}

// Setup table
export function setupTable(index, tbl, force) {
    var $tbl = $(tbl), $rows = $(tbl.rows);
    if (!tbl || !tbl.rows || !force && $tbl.data("isset") || tbl.tBodies.length == 0)
        return;

    // Set mouse over color
    var mouseOver = function(e) {
        var $this = $(this);
        if (!$this.data("selected") && ![ew.ROWTYPE_ADD, ew.ROWTYPE_EDIT].includes($this.data("rowtype"))) {
            var $tbl = $this.closest(".ew-table");
            if (!$tbl[0])
                return;
            $this.siblings("[data-rowindex='" + $this.data("rowindex") + "']").addBack().each(function(i, r) {
                $(r).addClass($tbl.data("rowhighlightclass") || "ew-table-highlight-row");
            });
        }
    }

    // Set mouse out color
    var mouseOut = function(e) {
        var $this = $(this);
        if (!$this.data("selected") && ![ew.ROWTYPE_ADD, ew.ROWTYPE_EDIT].includes($this.data("rowtype")))
            $this.siblings("[data-rowindex='" + $this.data("rowindex") + "']").addBack().each(setColor);
    }

    // Set selected row color
    var click = function(e) {
        var $this = $(this), tbl = $this.closest(".ew-table")[0],
            $target = $(e.target);
        if (!tbl || $target.hasClass("btn") || $target.hasClass("ew-preview-row-btn") || $target.is(":input"))
            return;
        if (!$this.data("checked")) {
            var selected = $this.data("selected");
            clearSelected(tbl); // Clear all other selected rows
            $this.siblings("[data-rowindex='" + $this.data("rowindex") + "']").addBack().each(function(i, r) {
                $(r).data("selected", !selected); // Toggle
                setColor(i, r);
            });
        }
    }

    var n = $rows.filter("[data-rowindex=1]").length || $rows.filter("[data-rowindex=0]").length || 1; // Alternate color every n rows
    var rows = $rows
        .filter(":not(.ew-template)")
        .each(function() {
            $(this.cells).removeClass("ew-table-last-row").last().addClass("ew-table-last-col"); // Cell of last column
        }).get();
    var div = $tbl.parentsUntil(".ew-grid", "." + ew.RESPONSIVE_TABLE_CLASS)[0];
    if (rows.length) {
        for (var i = 1; i <= n; i++) {
            var r = rows[rows.length - i]; // Last rows
            $(r.cells).each(function() {
                if (this.rowSpan == i) // Cell of last row
                    $(this).addClass("ew-table-last-row")
                        .toggleClass("ew-table-border-bottom", !!div && div.clientHeight > tbl.offsetHeight);
            });
        }
    }
    var form = $tbl.closest("form")[0];
    var attach = form && $(form.elements).filter("input#action:not([value^=grid])").length > 0;
    $(tbl.tBodies[tbl.tBodies.length - 1].rows) // Use last TBODY (avoid Opera bug)
        .filter(":not(.ew-template):not(.ew-table-preview-row)")
        .each(function(i) {
            var $r = $(this);
            if (attach && !$r.data("isset")) {
                if ([ew.ROWTYPE_ADD, ew.ROWTYPE_EDIT].includes($r.data("rowtype"))) // Add/Edit row
                    $r.on("mouseover", function() {this.edit = true;}).addClass("ew-table-edit-row");
                $r.on("mouseover", mouseOver).on("mouseout", mouseOut).on("click", click);
                $r.data("isset", true);
            }
            var sw = i % (2 * n) < n;
            $r.toggleClass("ew-table-row", sw).toggleClass("ew-table-alt-row", !sw);
        });
    setupGrid(index, $tbl.closest(".ew-grid")[0], force);
    $tbl.data("isset", true);
}

// Setup grid
export function setupGrid(index, grid, force) {
    var $grid = $(grid);
    if (!grid || !force && $grid.data("isset"))
        return;
    var multi = $grid.find("table.ew-multi-column-table").length, rowcnt;
    if (multi) {
        rowcnt = $grid.find("td[data-rowindex]").length;
    } else {
        rowcnt = $grid.find("table.ew-table > tbody").first().children("tr:not(.ew-table-preview-row, .ew-template)").length;
    }
    if (rowcnt == 0 && !$grid.find(".ew-grid-upper-panel, .ew-grid-lower-panel")[0])
        $grid.hide();
    // if (!$grid.find(".ew-grid-upper-panel:visible")[0])
    // 	$grid.css({"border-top-left-radius": 0, "border-top-right-radius": 0});
    // if (!$grid.find(".ew-grid-lower-panel:visible")[0])
    // 	$grid.css({"border-bottom-left-radius": 0, "border-bottom-right-radius": 0});
    if ($grid.find(".ew-grid-middle-panel:visible").hasClass(ew.RESPONSIVE_TABLE_CLASS) && $grid.width() > $(".content").width()) {
        $grid.addClass("d-flex");
        $grid.closest(".ew-detail-pages").addClass("d-block");
        $grid.closest(".ew-form").addClass("w-100");
        if (ew.USE_OVERLAY_SCROLLBARS)
            $grid.find(".ew-grid-middle-panel:not(.ew-preview-middle-panel)").overlayScrollbars(ew.overlayScrollbarsOptions);
    }
    $grid.data("isset", true);
}

// Add a row to grid
export function addGridRow(el) {
    var $grid = $(el).closest(".ew-grid"),
        $tbl = $grid.find("table.ew-table").last(), $p = $tbl.parent("div"),
        $tpl = $tbl.find("tr.ew-template");
    if (!el || !$grid[0] || !$tbl[0] || !$tpl[0])
        return false;
    var $lastrow = $($tbl[0].rows).last();
    $tbl.find("td.ew-table-last-row").removeClass("ew-table-last-row");
    var $row = $tpl.clone(true, true).removeClass("ew-template");
    var $form = $grid.find("div.ew-form[id^=f][id$=grid]");
    if (!$form[0])
        $form = $grid.find("form.ew-form[id^=f][id$=list]");
    var suffix = ($form.is("div")) ? "_" + $form.attr("id") : "";
    var $elkeycnt = $form.find("#key_count" + suffix);
    var keycnt = parseInt($elkeycnt.val(), 10) + 1;
    $row.attr({ "id": "r" + keycnt + $row.attr("id").substring(2), "data-rowindex": keycnt });
    var $els = $tpl.find("script:contains('$rowindex$')"); // Get scripts with rowindex
    $row.children("td").each(function() {
        $(this).find("*").each(function() {
            $.each(this.attributes, function(i, attr) {
                attr.value = attr.value.replace(/\$rowindex\$/g, keycnt); // Replace row index
            });
        });
    });
    $row.find(".ew-icon").closest("a, button").removeData("bs.tooltip").tooltip({ container: "body", placement: "bottom", trigger: "hover", sanitizeFn: ew.sanitizeFn });
    $elkeycnt.val(keycnt).after($("<input>").attr({
        type: "hidden",
        id: "k" + keycnt + "_action" + suffix,
        name: "k" + keycnt + "_action" + suffix,
        value: "insert"
    }));
    $lastrow.after($row);
    $els.each(function() {
        addScript(this.text.replace(/\$rowindex\$/g, keycnt));
    });
    var frm = $form.data("form");
    if (frm) {
        frm.initEditors();
        frm.initUpload();
    }
    setupTable(-1, $tbl[0], true);
    $p.scrollTop($p[0].scrollHeight);
    return false;
}

// Delete a row from grid
export function deleteGridRow(el, infix) {
    var $el = $(el).tooltip("hide").removeData("bs.tooltip"),
        $grid = $el.closest(".ew-grid, .ew-multi-column-grid"),
        $row = $el.closest("tr, div[data-rowindex]"),
        $tbl = $row.closest(".ew-table");
    if (!el || !$grid[0] || !$row[0])
        return false;
    var rowidx = parseInt($row.data("rowindex"), 10);
    var $form = $grid.find("div.ew-form[id^=f][id$=grid]");
    if (!$form[0])
        $form = $grid.find("form.ew-form[id^=f][id$=list]");
    var frm = $form.data("form");
    if (!$form[0] || !frm)
        return false;
    var suffix = ($form.is("div")) ? "_" + $form.attr("id") : "";
    var keycntname = "#key_count" + suffix;
    var _delete = function() {
        $row.remove();
        if ($grid.is(".ew-grid"))
            setupTable(-1, $tbl[0], true);
        if (rowidx > 0) {
            var $keyact = $form.find("#k" + rowidx + "_action" + suffix);
            if ($keyact[0]) {
                $keyact.val(($keyact.val() == "insert") ? "insertdelete" : "delete");
            } else {
                $form.find(keycntname).after($("<input>").attr({
                    type: "hidden",
                    id: "k" + rowidx + "_action" + suffix,
                    name: "k" + rowidx + "_action" + suffix,
                    value: "delete"
                }));
            }
        }
    };
    if (isFunction(frm.emptyRow) && frm.emptyRow(infix)) { // Empty row
        _delete();
    } else { // Confirm
        _prompt(ew.language.phrase("DeleteConfirmMsg"), (result) => {
            if (result)
                _delete();
        });
    }
    return false;
}

// HTML encode text
export function htmlEncode(text) {
    var str = String(text);
    return str.replace(/&/g, '&amp;').replace(/\"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

// Get form element(s) as single element or array of radio/checkbox
export function getElements(el, root) {
    var selector;
    if ($.isObject(el) && el.dataset) { // HTML element (e.g. radio/checkbox)
        selector = "[data-table='" + el.dataset.table + "'][data-field='" + el.dataset.field + "']:not([name^=o]):not([name^='x$'])";
    } else if ($.isString(el)) {
        selector = "[name='" + el + "']";
        var ar = el.split(" "); // Check if "#id name"
        if (ar.length == 2)
            selector = "[data-table='" + ar[0] + "'][data-field='" + getId(ar[1]) + "']:not([name^=o]):not([name^='x$'])"; // Remove []
    }
    var root = !$.isString(root) ? root : /^#/.test(root) ? root : "#" + root;
    selector = "input" + selector + ",select" + selector + ",textarea" + selector + ",button" + selector;
    var $els = (root) ? $(root).find(selector) : $(selector);
    if ($els.length == 1 && $els.is(":not([type=checkbox]):not([type=radio])"))
        return $els[0];
    return $els.get();
}

// Get first element (not necessarily form element)
export function getElement(name, root) {
    var root = $.isString(root) ? "#" + root : root,
        selector = "#" + name.replace(/([\$\[\]])/g, "\\$1") + ",[name='" + name + "']";
    return (root) ? $(root).find(selector)[0] : $(selector).first()[0];
}

// Get ancestor by function
export function getAncestorBy(node, fn) {
    while (node = node.parentNode) {
        if (node && node.nodeType == 1 && (!fn || fn(node)))
            return node;
    }
    return null;
}

// Check if an element is hidden
export function isHidden(el) {
    var $el = $(el);
    return $el.css("display") == "none" && !$el.closest(".dropdown-menu")[0] && !isModalLookup(el) && !isAutoSuggest(el) && !isHiddenTextArea(el) ||
        getAncestorBy(el, (node) => node.style.display == "none" && !node.classList.contains("tab-pane") && !node.classList.contains("collapse")) != null;
}

// Check if same text
export function sameText(o1, o2) {
    return (String(o1).toLowerCase() == String(o2).toLowerCase());
}

// Check if same string
export function sameString(o1, o2) {
    return (String(o1) == String(o2));
}

// Get element value
export function getValue(el, form) {
    if (!el)
        return "";
    let obj;
    if ($.isString(el)) {
        let ar = el.split(" ");
        if (ar.length == 2) { // Parent field in master table
            obj = getElements(el);
        } else {
            obj = getElements(el, form);
        }
    } else if (el.type == "radio" || el.type == "checkbox") { // Single radio/checkbox
        obj = getElements(el);
    } else {
        obj = el;
    }
    if (obj.options) { // Selection list
        if (obj.list) {
            let val = obj.values;
            return obj.multiple ? val : (val[0] || "");
        } else {
            let val = Array.prototype.filter.call(obj.options, option => option.selected && option.value !== "").map(option => option.value);
            return (obj.type == "select-multiple") ? val : (val[0] || "");
        }
    } else if ($.isNumber(obj.length)) { // Radio/Checkbox list, or element not found
        let val = $(obj).filter(":checked").map(function() {
            return this.value;
        }).get();
        return (obj.length == 1) ? val[0] : val;
    } else if (ew.isHiddenTextArea(obj)) {
        $(obj).data("editor").save();
        return obj.value;
    } else { // text/hidden
        let data = $(obj).data();
        if (data.lookup && data.multiple) // Modal-Lookup
            return obj.value.split(ew.MULTIPLE_OPTION_SEPARATOR);
        else
            return obj.value;
    }
}

// Get existing selected values as an array
export function getOptionValues(el, form) {
    var obj;
    if ($.isString(el)) {
        var ar = el.split(" ");
        if (ar.length == 2) { // Parent field in master table
            obj = getElements(el);
        } else {
            obj = getElements(el, form);
        }
    } else if (el.type == "radio" || el.type == "checkbox") { // Single radio/checkbox
        obj = getElements(el);
    } else {
        obj = el;
    }
    if (obj.options) { // Selection list
        if (obj.list)
            return obj.values;
        else
            return Array.prototype.filter.call(obj.options, option => option.selected && option.value !== "").map(option => option.value);
    } else if ($.isNumber(obj.length)) { // Radio/Checkbox list, or element not found
        return $(obj).filter(":checked").map(function() {
            return this.value;
        }).get();
    } else if (ew.isHiddenTextArea(obj)) {
        $(obj).data("editor").save();
        return [obj.value];
    } else { // text/hidden
        var data = $(obj).data();
        if (data.lookup && data.multiple) // Modal-Lookup
            return obj.value.split(ew.MULTIPLE_OPTION_SEPARATOR);
        else
            return [obj.value];
    }
}

// Get existing text of selected values as an array
export function getOptionTexts(el, form) {
    var obj;
    if ($.isString(el)) {
        var ar = el.split(" ");
        if (ar.length == 2) { // Parent field in master table
            obj = getElements(el);
        } else {
            obj = getElements(el, form);
        }
    } else {
        obj = el;
    }
    if (isAutoSuggest(obj)) { // AutoSuggest (before obj.options)
        return [obj.input.value];
    } else if (isModalLookup(obj)) { // Modal-Lookup (before obj.options)
        var $obj = $(obj);
        return $obj.parent().find(".ew-lookup-text .ew-option").map(function() {
            return $(this).text().trim();
        }).get();
    } else if (obj.options) { // Selection list
        return Array.prototype.filter.call(obj.options, option => option.selected && option.value !== "").map(option => option.text);
    } else if ($.isNumber(obj.length)) { // Radio/Checkbox list, or element not found
        return $(obj).filter(":checked").map(function() {
            return $(this).parent().text();
        }).get();
    } else if (ew.isHiddenTextArea(obj)) {
        $(obj).data("editor").save();
        return [obj.value];
    } else {
        return [obj.value];
    }
}

// Clear existing options
export function clearOptions(obj) {
    if (obj.options) { // Selection list
        var lo = (obj.type == "select-multiple" || // multiple
            obj.hasAttribute("data-dropdown") || // dropdown
            convertToBool(obj.getAttribute("data-pleaseselect")) === false || // data-pleaseselect="false"
            obj.length > 0 && obj.options[0].value != "") // non-empty first element
            ? 0 : 1;
        if (obj.list) {
            obj.removeAll();
        } else {
            for (var i = obj.length - 1; i >= lo; i--)
                obj.remove(i);
        }
        if (isAutoSuggest(obj)) {
            obj.input.value = "";
            obj.value = "";
        }
    }
}

/**
 * Get the name or id of an element
 *
 * @param {*} el - Element
 * @param {boolean} [remove=true] - Remove square brackets
 * @returns
 */
export function getId(el, remove) {
    var id = ($.isString(el)) ? el : ($(el).attr("name") || $(el).attr("id")); // Use name first (id may have suffix)
    return (remove !== false) ? id.replace(/\[\]$/, "") : id;
}

// Get display value separator
export function valueSeparator(index, obj) {
    var sep = $(obj).data("value-separator");
    return (Array.isArray(sep)) ? sep[index - 1] : (sep || ", ");
}

/**
 * Get display value
 *
 * @param {Object} opt - Option being displayed
 * @param {HTMLElment} obj - HTML element
 * @returns {string} Display value
 */
export function displayValue(opt, obj) {
    var text = opt.df;
    for (var i = 2; i <= 4; i++) {
        if (opt["df" + i] && opt["df" + i] != "") {
            var sep = valueSeparator(i - 1, obj);
            if ($.isUndefined(sep))
                break;
            if ($.isValue(text))
                text += sep;
            text += opt["df" + i];
        }
    }
    return text;
}

/**
 * Get HTML for a single option
 *
 * @param {*} val - Value of the option
 * @returns {string} HTML
 */
export function optionHtml(val) {
    return ew.OPTION_HTML_TEMPLATE.replace(/\{value\}/g, val);
}

/**
 * Get HTML for diplaying all options
 *
 * @param {string[]} options - Array of all options (HTML)
 * @param {number} max - Maximum number of options to show
 * @returns {string} HTML
 */
export function optionsHtml(options, max) {
    if (options.length > (max || ew.MAX_OPTION_COUNT)) { // More than max option count
        return ew.language.phrase("CountSelected").replace("%s", options.length);
    } else if (options.length) { // Some options
        var html = "";
        for (var i = 0; i < options.length; i++)
            html += optionHtml(options[i]);
        return html;
    } else { // No options
        return ew.language.phrase("PleaseSelect");
    }
}

/**
 * Create new option
 *
 * @param {(HTMLElement|array)} obj - Selection list
 * @param {Object} opt - Object for the new option
 * @param {form} f - form object of obj
 * @returns
 */
export function newOption(obj, opt, f) {
    var frm = forms.get(f.id),
        id = getId(obj),
        list = frm.getList(id),
        value = opt.lf,
        item = { lf: opt.lf, df1: opt.df, df2: opt.df2, df3: opt.df3, df4: opt.df4 },
        text;
    if (list.template && !isAutoSuggest(obj)) {
        text = list.template.render(item, ew.jsRenderHelpers);
    } else {
        text = displayValue(opt, obj) || value;
    }
    var args = { "data": item, "name": id, "form": f.$element, "value": value, "text": text };
    if (obj.options) { // Selection list
        let option;
        if (obj.list) {
            option = new SelectionListOption(args.value, args.text);
        } else {
            option = document.createElement("option");
            option.value = args.value;
            option.innerHTML = args.text;
        }
        args = { ...args, option };
        $document.trigger("newoption", [args]); // Fire "newoption" event for selection list
        if (obj.list) {
            obj.add(args.option.value, args.option.text);
        } else {
            obj.add(args.option);
        }
    }
    return args.text;
}

// Select combobox option
export function selectOption(obj, values) {
    if (!obj || !values)
        return;
    var $obj = $(obj);
    if (Array.isArray(values)) {
        if (obj.options) { // Selection list
            if (obj.list) {
                obj.value = values;
            } else {
                $obj.val(values);
                if (obj.type == "select-one" && obj.selectedIndex == -1)
                    obj.selectedIndex = 0; // Make sure an option is selected (IE)
            }
            if (isAutoSuggest(obj) && values.length == 1) {
                let opts = obj.options || [];
                for (let opt of opts) {
                    if (opt.value == values[0]) {
                        obj.value = opt.value;
                        obj.input.value = opt.text;
                        break;
                    }
                }
            } else if (isModalLookup(obj)) {
                let vals = [],
                    html = [],
                    opts = obj.options || [];
                for (let value of values) {
                    for (let opt of opts) {
                        if (value == opt.value) {
                            vals.push(opt.value);
                            html.push(optionHtml(opt.text));
                            break;
                        }
                    }
                }
                $obj.val(vals.join(ew.MULTIPLE_OPTION_SEPARATOR));
                $obj.parent().find(".ew-lookup-text").html(optionsHtml(html, $obj.data("maxcount")));
            }
        } else if (obj.type) {
            obj.value = values.join(ew.MULTIPLE_OPTION_SEPARATOR);
        }
    }
    // Auto-select if only one option
    function isAutoSelect(el) {
        if (!$(el).data("autoselect")) // data-autoselect="false"
            return false;
        var form = getForm(el);
        if (form) {
            if (/s(ea)?rch$/.test(form.id)) // Search forms
                return false;
            var list = forms.get(form.id).getList(el.id);
            if (list && list.parentFields.length == 0) // No parent fields
                return false;
            return true;
        }
        return false;
    }
    if (!isAutoSelect(obj))
        return;
    if (obj.options) { // Selection List
        if (!obj.list && obj.type == "select-one" && obj.options.length == 2 && !obj.options[1].selected) {
            obj.options[1].selected = true;
        } else if (obj.options.length == 1 && !obj.options[0].selected) {
            obj.options[0].selected = true;
        }
        if (obj.list)
            obj.render();
        if (isAutoSuggest(obj)) {
            let opts = obj.options || [];
            if (opts.length == 1) {
                obj.value = opts[0].value;
                obj.input.value = opts[0].text;
            }
        }
    }
}

// Ajax send
$document.ajaxSend(function(event, jqxhr, settings) {
    var url = settings.url;
    if (url.match(/\/(\w+preview|session)\?/i)) // Preview/Session page
        _removeSpinner(); // Preview has spinner already
    var apiUrl = getApiUrl(),
        isApi = url.startsWith(apiUrl), // Is API request
        allowed = isApi || url.startsWith(currentPage());
    if (!allowed && url.match(/^http/i)) {
        var objUrl = new URL(url);
        allowed = objUrl.hostname == currentUrl.hostname; // Same host name
    }
    if (allowed) {
        if (isApi && ew.API_JWT_TOKEN && !ew.IS_WINDOWS_AUTHENTICATION) // Do NOT set JWT authorization header if Windows Authentication
            jqxhr.setRequestHeader(ew.API_JWT_AUTHORIZATION_HEADER, "Bearer " + ew.API_JWT_TOKEN);
        if (settings.type == "GET") { // GET
            var ar = settings.url.split("?"), params = new URLSearchParams(ar[1]);
            params.set(ew.TOKEN_NAME_KEY, ew.TOKEN_NAME); // Add token name // PHP
            params.set(ew.ANTIFORGERY_TOKEN_KEY, ew.ANTIFORGERY_TOKEN); // Add antiforgery token // PHP
            ar[1] = params.toString();
            settings.url = ar[0] + (ar[1] ? "?" + ar[1] : "");
        } else { // POST
            if (settings.data instanceof FormData) { // FormData
                settings.data.set(ew.TOKEN_NAME_KEY, ew.TOKEN_NAME); // Add token name // PHP
                settings.data.set(ew.ANTIFORGERY_TOKEN_KEY, ew.ANTIFORGERY_TOKEN); // Add antiforgery token // PHP
            } else {
                var params = new URLSearchParams(settings.data);
                params.set(ew.TOKEN_NAME_KEY, ew.TOKEN_NAME); // Add token name // PHP
                params.set(ew.ANTIFORGERY_TOKEN_KEY, ew.ANTIFORGERY_TOKEN); // Add antiforgery token // PHP
                settings.data = params.toString();
            }
        }
    }
});

// Ajax start
$document.ajaxStart(function() {
    $document.data("_ajax", true);
    ew.addSpinner();
    $("form.ew-form").addClass("ew-wait").each(function() {
        var frm = forms.get(this.id);
        if (frm) {
            if (!frm.multiPage || !frm.multiPage.lastPageSubmit)
                frm.disableForm();
        }
    });
});

// Ajax stop (internal)
function _ajaxStop() {
    $("form.ew-form.ew-wait").removeClass("ew-wait").each(function() {
        var frm = forms.get(this.id);
        if (frm) {
            if (!frm.multiPage || !frm.multiPage.lastPageSubmit) {
                frm.enableForm();
            }
        }
    });
    ew.removeSpinner();
    $document.data("_ajax", false);
}

// Ajax stop/error
$document.ajaxStop(_ajaxStop).ajaxError(_ajaxStop);

// Execute JavaScript in HTML loaded by Ajax
export function executeScript(html, id) {
    let matches = html.replace(/<head>[\s\S]*<\/head>/, "").matchAll(/<script([^>]*)>([\s\S]*?)<\/script\s*>/ig);
    Array.from(matches).forEach((ar, i) => {
        let text = ar[2];
        if (/(\s+type\s*=\s*['"]*text\/javascript['"]*)|^((?!\s+type\s*=).)*$/i.test(ar[1]) && text)
            addScript(text, "scr_" + id + "_" + i++);
    });
}

// Strip JavaScript in HTML loaded by Ajax
export function stripScript(html) {
    let matches = html.matchAll(/<script([^>]*)>([\s\S]*?)<\/script\s*>/ig);
    for (let ar of matches) {
        let text = ar[0];
        if (/(\s+type\s*=\s*['"]*text\/javascript['"]*)|^((?!\s+type\s*=).)*$/i.test(ar[1]))
            html = html.replace(text, "");
    }
    return html;
}

// Add SCRIPT tag
export function addScript(text, id) {
    var scr = document.createElement("SCRIPT");
    if (id)
        scr.id = id;
    scr.text = text;
    return document.body.appendChild(scr); // Do not use jQuery so it can be removed
}

// Remove JavaScript added by Ajax
export function removeScript(id) {
    if (id)
        $("script[id^='scr_" + id + "_']").remove();
}

// Clean HTML loaded by Ajax for modal dialog
export function getContent(html) {
    let body = stripScript(html).match(/<body[\s\S]*>[\s\S]*<\/body>/i);
    return body ? $(body[0]).not("div[id^=ew].modal, #ew-tooltip, #ew-drilldown-panel, #cookie-consent, #template-upload, #template-download") : $();
}

// Get all options of Selection list or Radio/Checkbox list as array
export function getOptions(obj) {
    return obj.options ? Array.prototype.map.call(obj.options, (opt) => [opt.value, opt.text]) : [];
}

/**
 * Show Add Option dialog
 *
 * @param {Object} args - Arguments
 * @param {Object} args.frm - form object
 * @param {HTMLElement} args.lnk - Add option anchor element
 * @param {string} args.el - Form element name
 * @param {string} args.url - URL of the Add form
 * @returns
 */
export function addOptionDialogShow(args) {

    // Hide dialog
    var _hide = function() {
        removeScript($dlg.data("args").el);
        var frm = $dlg.removeData("args").find(".modal-body form").data("form");
        if (frm)
            frm.destroyEditor();
        $dlg.find(".modal-body").html("");
        $dlg.find(".modal-footer .btn-primary").off();
        $dlg.data("showing", false);
    }

    var $dlg = ew.addOptionDialog || $("#ew-add-opt-dialog").on("hidden.bs.modal", _hide);
    if (!$dlg[0]) {
        _alert("DIV #ew-add-opt-dialog not found.");
        return;
    }
    if ($dlg.data("showing"))
        return;
    $dlg.data("showing", true);

    // Submission success
    var _submitSuccess = function(data) {
        var results = data,
            args = $dlg.data("args"),
            frm = forms.get(args.lnk), // form object
            objName = $dlg.find(".modal-body form input[name='" + ew.API_OBJECT_NAME + "']").val(), // Get object name from form
            el = args.el, // HTML element name
            re = /^x(\d+)_/,
            m = el.match(re), // Check row index
            prefix = m ? m[0] : "x_",
            index = m ? m[1] : -1,
            name = el.replace(re, "x_"),
            list = frm.getList(el);
        if ($.isString(data))
            results = parseJson(data);
        if (results?.success && results[objName]) { // Success
            $dlg.modal("hide");
            var result = results[objName],
                form = frm.$element[0], // HTML form or DIV
                obj = getElements(el, form);
            if (obj) {
                var lf = list.linkField,
                    dfs = list.displayFields.slice(), // Clone
                    ffs = list.filterFields.slice(), // Clone
                    pfs = list.parentFields.slice(); // Clone
                pfs.forEach((pf, i) => {
                    if (pf.split(" ").length == 1) // Parent field in the same table, add row index
                        pfs[i] = pfs[i].replace(/^x_/, prefix);
                });
                var lfv = (lf != "") ? result[lf] : "",
                    row = { lf: lfv };
                dfs.forEach((df, i) => {
                    if (df in result)
                        row["df" + (i || "")] = result[df];
                });
                ffs.forEach((ff, i) => {
                    if (ff in result)
                        row["ff" + (i || "")] = result[ff];
                });
                if (lfv && dfs.length > 0 && row["df"]) {
                    if (list.ajax === null) // Non-Ajax
                        list.lookupOptions.push(row);
                    var arp = pfs.map(pf => getOptionValues(pf, form)), // Get the parent field values
                        args = {"data": row, "parents": arp, "valid": true, "name": getId(obj), "form": form};
                    $document.trigger("addoption", [args]);
                    if (args.valid) { // Add the new option
                        var ar = getOptions(obj),
                            vals = [];
                        var txt = newOption(obj, row, form);
                        if (obj.options) {
                            obj.options[obj.options.length - 1].selected = true;
                            if (obj.list) { // Radio/Checkbox list
                                obj.render();
                                $(obj.target).find("input").last().trigger("focus");
                            }
                            if (isAutoSuggest(obj)) {
                                $(obj).val(lfv).trigger("change");
                                $(obj.input).val(txt).trigger("focus");
                            } else if (isModalLookup(obj)) {
                                var $obj = $(obj), $lu = $(getElement("lu_" + args.name, form));
                                if (obj.multiple) { // Add to existing values
                                    var val = $(obj).val(), vals = [], nv = String(lfv);
                                    if (val !== "")
                                        vals = val.split(ew.MULTIPLE_OPTION_SEPARATOR);
                                    if (!vals.includes(nv)) {
                                        vals.push(nv);
                                        $obj.val(vals.join()).trigger("change");
                                        var html = $lu.html(), arOpt = $lu.find(".ew-option").map(function() {
                                            return $(this).html();
                                        }).get();
                                        if (arOpt.length) { // Some options selected
                                            arOpt.push(txt);
                                            $lu.html(optionsHtml(arOpt, $obj.data("maxcount")));
                                        } else if (html == ew.language.phrase("PleaseSelect")) { // No options selected
                                            $lu.html(optionHtml(txt));
                                        } else if (html) { // Many options selected
                                            $lu.html(ew.language.phrase("CountSelected").replace("%s", vals.length));
                                        }
                                    }
                                } else {
                                    $obj.val(lfv).trigger("change");
                                    $lu.html(txt);
                                }
                            } else {
                                $(obj).trigger("change").trigger("focus");
                            }
                        }
                        var $form = $(form), suffix = ($form.is("div")) ? "_" + $form.attr("id") : "";
                        var cnt = $form.find("#key_count" + suffix).val();
                        if (cnt > 0) { // Grid-Add/Edit, update other rows
                            for (var i = 1; i <= cnt; i++) {
                                if (i == index)
                                    continue;
                                var obj2 = getElements(name.replace(/^x/, "x" + i), form);
                                var ar2 = getOptions(obj2), vals = [];
                                if (JSON.stringify(ar) != JSON.stringify(ar2)) // Not same options
                                    continue;
                                newOption(obj2, row, form);
                                if (obj2.options && obj.list) // Radio/Checkbox list
                                    obj2.render();
                            }
                        }
                    }
                }
            }
        } else { // Failure
            if (results?.error) {
                 if ($.isString(results.error))
                    showToast(results.error);
                else if ($.isString(results.error?.description))
                    showToast(results.error.description);
            } else {
                var msg,
                    $div = $("<div></div>").html(data).find("div.ew-message-dialog");
                if ($div[0]) {
                    msg = $div.html();
                } else {
                    msg = results?.failureMessage || data;
                    if (!msg || String(msg).trim() == "")
                        msg = ew.language.phrase("InsertFailed");
                }
                showToast(msg);
            }
        }
    }

    // Fail
    var _fail = function(o) {
        $dlg.modal("hide");
        _alert("Server Error " + o.status + ": " + o.statusText);
    }

    // Submit
    var _submit = function(e) {
        let $dlg = ew.addOptionDialog,
            form = $dlg.find(".modal-body form")[0],
            frm = forms.get(form.id),
            btn = e ? e.target : null,
            $btn = $(btn);
        if (frm.canSubmit()) {
            $btn.prop("disabled", false).removeClass("disabled");
            $body.css("cursor", "wait");
            $.post(getApiUrl([ew.API_ADD_ACTION, form.elements[ew.API_OBJECT_NAME].value]), $(form).serialize(), _submitSuccess).fail(_fail).always(function() {
                frm.enableForm();
                $btn.prop("disabled", false).removeClass("disabled");
                $body.css("cursor", "default");
            });
        }
        return false;
    }

    $dlg.modal("hide");
    $dlg.data("args", args);

    // Get form HTML
    var success = function(data) {
        var frm = forms.get(args.lnk),
            prefix = "x_",
            m = args.el.match(/^(x\d+_)/);
        if (m) // Contains row index
            prefix = m[1];
        var list = frm.getList(args.el),
            pfs = list.parentFields.slice() // Clone
                .map(pf => (pf.split(" ").length == 1) ? pf.replace(/^x_/, prefix) : pf), // Parent field in the same table, add row index
            form = frm.htmlForm,
            ar = pfs.map(pf => getOptionValues(pf, form)),
            ar2 = pfs.map(pf => getOptionTexts(pf, form)),
            ffs = list.filterFieldVars.slice(); // Clone
        $dlg.find(".modal-title").html($(args.lnk).closest(".ew-add-opt-btn").data("title"));
        $dlg.find(".modal-body").html(stripScript(data));
        var form = $dlg.find(".modal-body form")[0];
        if (form) { // Set the filter field value
            $(form).on("keydown", function(e) {
                if (e.key == "Enter" && e.target.nodeName != "TEXTAREA")
                    return _submit();
            });
            ar.forEach((v, i) => {
                (function() {
                    var obj = getElements(ffs[i], form);
                    if (obj) {
                        if (obj.options || obj.length) { // Selection list
                            $(obj).first().one("updated", () => selectOption(obj, v));
                        } else {
                            selectOption(obj, v);
                        }
                    }
                })();
            });
        }
        ew.addOptionDialog = $dlg.modal("show");
        $dlg.find(".modal-footer .btn-primary").click(_submit).focus();
        executeScript(data, args.el);
        if (form) { // Set the filter field value
            ar.forEach((v, i) => {
                var obj = getElements(ffs[i], form);
                if (obj) {
                    if (isAutoSuggest(obj)) { // AutoSuggest
                        obj.value = v[0];
                        obj.input.value = ar2[i][0];
                        obj.add(v[0], ar2[i][0], true);
                    } else if (isModalLookup(obj)) { // Modal-Lookup
                        obj.value = v[0];
                        updateOptions.call(forms.get(form.id), obj);
                    } else if (obj.options || obj.length) { // Selection list
                        // Skip
                    } else { // Text
                        obj.value = v[0];
                    }
                }
            });
        }
        $dlg.trigger("load.ew");
    };
    $.get(args.url, success).fail(_fail);
}

// Hide Modal dialog
export function modalDialogHide(e) {
    var $dlg = $(this), args = $dlg.data("args");
    removeScript("ModalDialog");
    var frm = $dlg.removeData("args").find(".modal-body form").data("form");
    if (frm)
        frm.destroyEditor();
    var $bd = $dlg.find(".modal-body").html("");
    if ($bd.ewjtable && $bd.ewjtable("instance"))
        $bd.ewjtable("destroy");
    $dlg.find(".modal-footer .btn-primary").off();
    $dlg.find(".modal-dialog").removeClass(function(index, className) {
        var m = className.match(/table\-\w+/);
        return (m) ? m[0] : "";
    });
    $dlg.data("showing", false);
    $dlg.data("url", null);
    if (args && args.reload)
        window.location.reload();
}

/**
 * Show modal dialog
 *
 * @param {Object} args - Arguments
 * @param {HTMLFormElement} args.f - Form of List page
 * @param {HTMLElement} args.lnk - Anchor element
 * @param {string} args.url - URL of the form
 * @param {string|null} args.btn - Button phrase ID
 * @param {string} args.caption - Caption in dialog header
 * @param {boolean} args.reload - Reload page after hiding dialog or not
 * @param {string} args.size - Size of the dialog 'modal-sm'|''|modal-lg'|'modal-xl'(default)
 * @returns false
 */
export function modalDialogShow(args) {
    $(args.lnk).tooltip("hide");
    var f = args.f;
    if (f && !keySelected(f)) {
        _prompt("<p class=\"text-danger\">" + ew.language.phrase("NoRecordSelected") + "</p>");
        return false;
    }

    var $dlg = ew.modalDialog || $("#ew-modal-dialog").on("hidden.bs.modal", modalDialogHide); // div#ew-modal-dialog always exists
    if ($dlg.data("showing") && $dlg.data("url") == args.url)
        return false;
    $dlg.data({ showing: true, url: args.url });
    args.reload = false;

    // size
    if (args.size === "modal-sm") { // 300px
        $dlg.find(".modal-dialog").toggleClass("modal-sm", true).toggleClass("modal-lg modal-xl", false);
    } else if (args.size === "") { // 500px
        $dlg.find(".modal-dialog").toggleClass("modal-sm modal-lg modal-xl", false);
    } else if (args.size === "modal-lg") { // 800px
        $dlg.find(".modal-dialog").toggleClass("modal-lg", true).toggleClass("modal-sm modal-xl", false);
    } else { // Default = 1140px
        $dlg.find(".modal-dialog").toggleClass("modal-xl", true).toggleClass("modal-sm modal-lg", false);
    }

    // caption
    var _caption = function() {
        var args = $dlg.data("args");
        var $lnk = $(args.lnk);
        return args.caption || $lnk.data("caption") || $lnk.data("original-title") || "";
    };

    // button text
    var _button = function() {
        var args = $dlg.data("args");
        if ($.isNull(args.btn))
            return "";
        else if (args.btn && args.btn != "")
            return ew.language.phrase(args.btn);
        else
            return _caption();
    };

    // fail
    var _fail = function(o) {
        $dlg.modal("hide");
        if (o.status)
            _alert("Server Error " + o.status + ": " + o.statusText);
    }

    // always
    var _always = function(o) {
        $body.css("cursor", "default");
    }

    // check if current page
    var _current = function(url) {
        var a = $("<a>", { href: url })[0];
        return window.location.pathname.endsWith(a.pathname);
    }

    /**
     * handle result
     *
     * @param {Object} result - Result object
     * @param {string|Object} result.error - Error message or object
     * @param {string} result.error.message - Error message
     * @param {string} result.error.description - Error message
     * @param {string} result.failureMessage - Failure message
     * @param {string} result.successMessage - Success message
     * @param {string} result.warningMessage - Warning message
     * @param {string} result.message - Message
     * @param {string} result.url - Redirection URL
     * @param {string} result.modal - Redirect to result.url in current modal dialog
     * @param {boolean} result.view - result.url is View page => No primary button
     * @param {string} result.caption - Caption of modal dialog for result.url
     * @param {boolean} result.reload - Reload current page
     */
    var handleResult = function(result) {
        var cb = null,
            url = result.url,
            reload = result.reload;
        if (url || reload) {
            cb = function() {
                if (url) {
                    if (result.modal && !_current(url)) {
                        var args = $dlg.data("args");
                        args.reload = true;
                        if (result.caption)
                            args.caption = result.caption;
                        args.btn = result.view ? null : "";
                        $dlg.data("args", args);
                        url += (url.split("?").length > 1 ? "&" : "?") + "modal=1&rnd=" + random();
                        $body.css("cursor", "wait");
                        $.get(url).done(success).fail(_fail).always(_always);
                    } else {
                        $dlg.modal("hide");
                        window.location = sanitizeUrl(url);
                    }
                } else if (reload) {
                    $dlg.modal("hide");
                    window.location.reload();
                }
            };
        }
        if ($.isString(result.failureMessage)) {
            _alert(result.failureMessage);
        } else if ($.isString(result.warningMessage)) {
            _alert(result.warningMessage, cb, "warning");
        } else if ($.isString(result.message)) {
            _alert(result.message, cb, "body");
        } else if ($.isString(result.successMessage)) {
            _alert(result.successMessage, cb, "success");
        } else if (result.error) {
            if ($.isString(result.error))
                _alert(result.error);
            else if ($.isString(result.error?.message))
                _alert(result.error.message);
            else if ($.isString(result.error?.description))
                _alert(result.error.description);
        } else if (cb) {
            cb();
        }
    }

    // submit success
    var _submitSuccess = function(data) {
        var result = parseJson(data);
        if ($.isObject(result)) {
            handleResult(result);
        } else {
            var body = getContent(data);
            if (body.length) { // Has HTML elements
                var $bd = $dlg.find(".modal-body").html(body);
                var footer = "";
                var cf = $bd.find("#confirm");
                var ct = $bd.find("#conflict");
                if (ct && ct.val() == "1") { // Conflict page
                    footer += "<button type=\"button\" id=\"btn-overwrite\" class=\"btn btn-primary ew-btn\">" + ew.language.phrase("OverwriteBtn") + "</button>";
                    footer += "<button type=\"button\" id=\"btn-reload\" class=\"btn btn-default ew-btn\">" + ew.language.phrase("ReloadBtn") + "</button>";
                    footer += "<button type=\"button\" class=\"btn btn-default ew-btn\" data-dismiss=\"modal\">" + ew.language.phrase("CancelBtn") + "</button>";
                    $dlg.find(".modal-footer").html(footer);
                    $dlg.find(".modal-footer #btn-overwrite").on('click', {action: 'overwrite'}, _submit);
                    $dlg.find(".modal-footer #btn-reload").on('click', {action: 'show'}, _submit);
                } else if (cf && cf.val() == "confirm") { // Confirm page
                    footer += "<button type=\"button\" class=\"btn btn-primary ew-btn\">" + ew.language.phrase("ConfirmBtn") + "</button>";
                    footer += "<button type=\"button\" class=\"btn btn-default ew-btn\">" + ew.language.phrase("CancelBtn") + "</button>";
                    $dlg.find(".modal-footer").html(footer);
                    $dlg.find(".modal-footer .btn-primary").click(_submit).focus();
                    $dlg.find(".modal-footer .btn-default").on("click", {action: "cancel"}, _submit);
                } else { // Normal page
                    var btn = _button();
                    if (btn)
                        footer += "<button type=\"button\" class=\"btn btn-primary ew-btn\">" + btn + "</button>";
                    footer += "<button type=\"button\" class=\"btn btn-default ew-btn\" data-dismiss=\"modal\">" + ew.language.phrase("CancelBtn") + "</button>";
                    $dlg.find(".modal-footer").html(footer);
                    $dlg.find(".modal-footer .btn-primary").addClass("ew-submit").click(_submit).focus();
                }
                executeScript(data, "ModalDialog");
                $dlg.trigger("load.ew"); // Trigger load event for, e.g. Use JavaScript popup message
            } else if (data) {
                $dlg.modal("hide");
                ew.alert(data);
            }
        }
    }

    // submit
    var _submit = function(e) {
        var form = $dlg.find(".modal-body form")[0],
            $form = $(form),
            frm = forms.get(form.id),
            action = e && e.data ? e.data.action : null,
            btn = e ? e.target : null;
        if (btn) {
            if (btn.classList.contains("disabled"))
                return false;
            frm.enableForm = function() {
                $(btn).prop("disabled", false).removeClass("disabled");
            };
            frm.disableForm = function() {
                $(btn).prop("disabled", true).addClass("disabled");
            };
        }
        var input = form.elements["action"];
        if (action && input)
            input.value = action; // Update action
        if (action == "cancel") { // Cancel
            $.post($form.attr("action"), $form.serialize(), success).fail(_fail).always(_always);
        } else if (frm.canSubmit()) {
            $body.css("cursor", "wait");
            $.post($form.attr("action"), $form.serialize(), _submitSuccess).fail(_fail).always(function() {
                frm.enableForm();
                _always();
            });
        }
        return false;
    }

    $dlg.modal("hide");
    $dlg.data("args", args);

    var success = function(data) {
        var result = parseJson(data);
        if ($.isObject(result)) {
            handleResult(result);
        } else {
            var args = $dlg.data("args");
            var $lnk = $(args.lnk);
            $dlg.find(".modal-title").html(_caption());
            var footer = "";
            var btn = _button();
            if (btn)
                footer += "<button type=\"button\" class=\"btn btn-primary ew-btn\">" + btn + "</button>";
            if (footer != "")
                footer += "<button type=\"button\" class=\"btn btn-default ew-btn\" data-dismiss=\"modal\">" + ew.language.phrase("CancelBtn") + "</button>";
            else
                footer = "<button type=\"button\" class=\"btn btn-default ew-btn\" data-dismiss=\"modal\">" + ew.language.phrase("CloseBtn") + "</button>";
            $dlg.find(".modal-footer").html(footer);
            var body = getContent(data);
            $dlg.find(".modal-body").html(body);
            var table = $lnk.data("table");
            if (table)
                $dlg.find(".modal-dialog").addClass("table-" + table);
            var $btn = $dlg.find(".modal-footer .btn-primary").addClass("ew-submit").click(_submit);
            $dlg.find(".modal-body form").on("keydown", function(e) {
                if (e.key == "Enter" && e.target.nodeName != "TEXTAREA")
                    return $btn.click();
            });
            ew.modalDialog = $dlg.modal("show");
            executeScript(data, "ModalDialog");
            $dlg.trigger("load.ew"); // Trigger load event for, e.g. YouTube videos, ReCAPTCHA and Google maps
            $btn.focus();
        }
    };

    $body.css("cursor", "wait");

    var url = args.url;
    if (f) { // Post form
        var $f = $(f);
        if (!f.elements.modal)
            $("<input>").attr({type: "hidden", name: "modal", value: "1"}).appendTo($f);
        $.post(url, $f.serialize(), success).fail(_fail).always(_always);
    } else {
        url += (url.split("?").length > 1 ? "&" : "?") + "modal=1&rnd=" + random();
        $.get(url, success).fail(_fail).always(_always);
    }

    return false;
}

// Show Modal Lookup
export function modalLookupShow(args) {
    var el = args.el, f = getForm(args.lnk);
    if (!f || !el)
        return;

    var $dlg = ew.modalLookupDialog || $("#ew-modal-lookup-dialog").on("hidden.bs.modal", modalDialogHide);
    if (!$dlg[0]) {
        _alert("DIV #ew-modal-lookup-dialog not found.");
        return;
    }
    if ($dlg.data("showing"))
        return;
    $dlg.data("showing", true);

    var $f = $(f),
        $input = $f.find("[id='" + el + "']"), // id may contains "[]"
        $bd = $dlg.find(".modal-body"),
        $lnk = $(args.lnk),
        $lu = $lnk.closest(".ew-lookup-list").find(".ew-lookup-text").trigger("focus"),
        oid = getId(el, false),
        m = oid.match(/^([xy])(\d*)_/),
        rowindex = m ? m[2] : "",
        list = forms.get(f.id).getList(el);

    // Format data
    var _format = function(data) {
        if (data.result == "OK" && Array.isArray(data.records)) {
            data.records.forEach(function(ar, index) {
                var item;
                if (Array.isArray(ar))
                    item = { "lf": ar[0], "df1": ar[1], "df2": ar[2], "df3": ar[3], "df4": ar[4] };
                else if ($.isObject(ar))
                    item = { lf: ar.lf, df1: ar.df, df2: ar.df2, df3: ar.df3, df4: ar.df4 };
                var txt = displayValue(ar, $input);
                if (list.template) {
                    item["df"] = list.template.render(item, ew.jsRenderHelpers);
                } else {
                    item["df"] = txt;
                }
                item["txt"] = txt;
                data.records[index] = item;
            });
        }
        return data;
    }

    // Set AutoSuggest
    var setAutoSuggest = function(value, text) {
        if (!isAutoSuggest($input))
            return;
        let el = $input[0];
        el.add(value, text, true);
        el.input.value = text;
    }

    // Add option
    var addOpt = function(ar) {
        // Check if selected records are in the current page
        var vals = [], html = [], opts = [], txts = [], useText = !args.m && args.srch;
        $bd.ewjtable("selectedRows").each(function() {
            var record = $(this).data("record");
            vals.push(record.lf);
            html.push(record.df);
            opts.push(record.df);
            txts.push(record.txt); // Text for Auto-Suggest
        });
        if (ar.sort().join() === vals.sort().join()) { // All selected records are from the current page
            $lu.html(optionsHtml(opts, $input.data("maxcount")));
            setAutoSuggest(vals.join(), txts.join(", "));
            $input.val(useText ? html.join(", ") : vals.join()).trigger("change");
        } else { // Get selected records from server
            var data = Object.assign({ page: list.page, field: list.field, ajax: "modal", keys: ar }, getUserParams('#p_' + oid, f));
            $body.css("cursor", "wait");
            $.ajax(getApiUrl(ew.API_LOOKUP_ACTION), {
                type: "POST",
                dataType: "json",
                data: data
            }).done(_format).then(function(data) {
                if (data.result == "OK" && Array.isArray(data.records)) {
                    var vals = [], html = [], opts = [], txts = [], results = data.records;
                    for (let result of results) {
                        vals.push(result.lf);
                        html.push(result.df)
                        opts.push(result.df);
                        txts.push(result.txt); // Text for Auto-Suggest
                    }
                    $lu.html(optionsHtml(opts, $input.data("maxcount")));
                    setAutoSuggest(vals.join(), txts.join(", "));
                    $input.val(useText ? html.join(", ") : vals.join()).trigger("change");
                }
            }).always(function() {
                $body.css("cursor", "default");
            });
        }
    }

    // Submit
    var _submit = function() {
        addOpt(arLinkValue);
        $dlg.modal("hide");
        return false;
    }

    // Hide
    $dlg.modal("hide");
    $dlg.data("args", args);
    var _timer, $search;

    // Success
    var success = function(data) {
        if (data.result == "OK") {
            $dlg.find(".modal-title").html($lnk.attr("title") || $lnk.data("original-title"));
            $dlg.find(".modal-body .ewjtable thead").toggle(!!args.m);
            $dlg.find(".modal-footer").html('<button type="button" id="btn-select" class="btn btn-primary ew-btn">' + ew.language.phrase("SelectBtn") + '</button>' +
                '<button type="button" class="btn btn-default ew-btn" data-dismiss="modal">' + ew.language.phrase("CancelBtn") + '</button>');
            $search = $dlg.find(".modal-header .modal-tools input[name=sv]").off("keyup.ew").on("keyup.ew", function(e) {
                    if (_timer)
                        _timer.cancel();
                    _timer = $.later(ew.LOOKUP_DELAY, null, function() {
                        $bd.ewjtable("load", { "sv": $search.val() });
                    });
                });
            $dlg.find(".modal-footer #btn-select").click(_submit); // Select
            ew.modalLookupDialog = $dlg.modal("show");
            $search.focus();
        } else {
            _alert(data.message);
        }
    };

    var arp = [];
    var linkValue = $input.val(); // Link values
    var arLinkValue = (linkValue !== "") ? linkValue.split(ew.MULTIPLE_OPTION_SEPARATOR) : [];
    var data = Object.assign({ page: list.page, field: list.field }, getUserParams('#p_' + oid, f));

    // Add parent field values
    var parentId = list.parentFields.slice(); // Clone
    if (rowindex != "") {
        for (var i = 0, len = parentId.length; i < len; i++) {
            var ar = parentId[i].split(" ");
            if (ar.length == 1) // Parent field in the same table, add row index
                parentId[i] = parentId[i].replace(/^x_/, "x" + rowindex + "_");
        }
    }
    if (parentId.length > 0) {
        for (var i = 0, len = parentId.length; i < len; i++)
            arp.push(getOptionValues(parentId[i], f));
    }
    for (var i = 0, cnt = arp.length; i < cnt; i++) // Filter by parent fields
        data["v" + (i + 1)] = arp[i].join(ew.MULTIPLE_OPTION_SEPARATOR);

    $body.css("cursor", "wait");
    $bd.ewjtable({
        paging: true,
        pageSize: args.n,
        pageSizes: [],
        pageSizeChangeArea: false,
        pageList: "minimal",
        selecting: true,
        selectingCheckboxes: true,
        multiselect: !!args.m,
        actions: {
            "listAction": function(postData, jtParams) {
                var _data = Object.assign({}, data, {
                    ajax: "modal",
                    start: jtParams.start,
                    recperpage: jtParams.recperpage
                });
                if ($.isObject(postData)) // Search
                    Object.assign(_data, postData);
                return $.ajax(getApiUrl(ew.API_LOOKUP_ACTION), {
                        type: "POST",
                        dataType: "json",
                        data: _data
                    }).done(_format).always(function() {
                        $body.css("cursor", "default");
                    });
            }
        },
        messages: {
            serverCommunicationError: ew.language.phrase("ServerCommunicationError"),
            loadingMessage: '<div class="' + ew.spinnerClass + ' m-3 ew-loading" role="status"><span class="sr-only">' + ew.language.phrase("Loading") + '</span></div>',
            noDataAvailable: ew.language.phrase("NoRecord"),
            close: ew.language.phrase("CloseBtn"),
            pagingInfo: ew.language.phrase("Record") + " {0}-{1} " + ew.language.phrase("Of") + " {2}",
            pageSizeChangeLabel: ew.language.phrase("RecordsPerPage"),
            gotoPageLabel: ew.language.phrase("Page")
        },
        fields: {
            "lf": { key: true, list: false},
            "df": {}
        },
        recordsLoaded: function(e, data) {
            var selectedRows = $(e.target).find(".ewjtable-data-row").filter(function() {
                return arLinkValue.includes(String($(this).data("record-key")));
            });
            $(e.target).ewjtable("selectRows", selectedRows);
        },
        selectionChanged: function(e, data) {
            var $rows = data.rows;
            if ($rows) {
                if (!args.m)
                    arLinkValue = [];
                $rows.each(function() {
                    var $row = $(this);
                    var key = String($row.data("record-key"));
                    var index = arLinkValue.indexOf(key);
                    var selected = $row.hasClass("ewjtable-row-selected");
                    if (selected && index == -1)
                        arLinkValue.push(key);
                    else if (!selected && index > -1)
                        arLinkValue.splice(index, 1);
                });
            }
        }
    }).ewjtable("load", null, success);
}

/**
 * Show dialog for import
 *
 * @param {Object} args - Arguments
 * @param {string} args.hdr - Dialog header
 * @param {HTMLElement} args.lnk - Anchor element
 * @returns
 */
export function importDialogShow(args) {
    $(args.lnk).tooltip("hide");
    var $dlg = ew.importDialog || $("#ew-import-dialog");
    if (!$dlg[0]) {
        _alert("DIV #ew-import-dialog not found.");
        return false;
    }

    var $input = $dlg.find("#importfiles"),
        $bd = $dlg.find(".modal-body"),
        $data = $bd.find(":input[id!=importfiles]"),
        $message = $bd.find(".message"),
        $progress = $bd.find(".progress"),
        timer;

    // Disable buttons
    var disableButtons = function() {
        $dlg.find(".modal-footer .btn").prop("disabled", true);
    }

    // Enable buttons
    var enableButtons = function() {
        $dlg.find(".modal-footer .btn").prop("disabled", false);
    }

    // Show message
    var showMessage = function(msg, classname) {
        var $msg = $("<div>" + msg + "</div>");
        if (classname)
            $msg.addClass(classname);
        $message.removeClass("d-none").html($msg);
        if (classname == "text-danger")
            enableButtons();
    }

    // Hide message
    var hideMessage = function() {
        $message.addClass("d-none").html("");
    }

    // Show progress
    var showProgress = function(pc, classname) {
        $progress.removeClass("d-none").find(".progress-bar").removeClass("bg-success bg-info").addClass(classname || "bg-success")
            .attr("aria-valuenow", pc).css("width", pc + "%").html(pc + "%");
    }

    // Hide progress
    var hideProgress = function() {
        $progress.addClass("d-none").find(".progress-bar").attr("aria-valuenow", 0).css("width", "0%").html("0%");
    }

    // Upload progress
    var uploadProgress = function(data) {
        var pc = parseInt(100 * data.loaded / data.total);
        showProgress(pc, "bg-info");
        if (pc === 100) {
            showMessage(ew.language.phrase("ImportMessageUploadComplete"), "text-info");
        } else {
            showMessage(ew.language.phrase("ImportMessageUploadProgress").replace("%p", pc), "text-info");
        }
    }

    // Update progress (import)
    var updateProgress = function(result) {
        try {
            var cnt = parseInt(result.count), tcnt = parseInt(result.totalCount), filename = result.file;
            if (tcnt > 0 && $dlg.find(".modal-footer .ew-close-btn").data("import-progress")) { // Show progress
                var pc = parseInt(100 * cnt / tcnt);
                showProgress(pc);
                showMessage(ew.language.phrase("ImportMessageProgress").replace("%t", tcnt).replace("%c", cnt).replace("%f", filename), "text-info");
            }
        } catch(e) {}
    }

    // Import progress
    var importProgress = function() {
        var url = getApiUrl(ew.API_PROGRESS_ACTION), data = { "rnd": random() };
        data[ew.API_FILE_TOKEN_NAME] = $input.data(ew.API_FILE_TOKEN_NAME);
        $.get(url, data, updateProgress, "json");
    }

    // Import complete
    var importComplete = function(result) {
        var maxErrorCount = 5;
        var msg = "";
        showProgress(100);
        var fileResults = result.files;
        $dlg.find(".modal-footer .ew-close-btn").data("import-progress", false); // Stop import progress
        if (Array.isArray(fileResults)) {
            for (var i = 0, len = fileResults.length; i < len; i++) {
                var fileResult = fileResults[i],
                    tcnt = fileResult.totalCount || 0,
                    cnt = fileResult.count || 0,
                    scnt = fileResult.successCount || 0,
                    fcnt = fileResult.failCount || 0;
                if (msg != "")
                    msg += "<br>";
                if (fileResult.success) {
                    msg += ew.language.phrase("ImportMessageSuccess").replace("%t", tcnt).replace("%c", cnt).replace("%f", fileResult.file);
                } else {
                    msg += ew.language.phrase("ImportMessageError1").replace("%t", tcnt).replace("%c", cnt).replace("%f", fileResult.file).replace("%s", scnt).replace("%e", fcnt);
                    if (fileResult.error)
                        msg += ew.language.phrase("ImportMessageError2").replace("%e", fileResult.error);
                    var showLog = true;
                    if (fileResult.failList) {
                        var ecnt = 0;
                        for (var i = 1; i <= cnt; i++) {
                            if (fileResult.failList["row" + i]) {
                                ecnt += 1;
                                msg += "<br>" + ew.language.phrase("ImportMessageError3").replace("%i", i).replace("%d", fileResult.failList["row" + i]);
                            }
                            if (ecnt >= maxErrorCount)
                                break;
                        }
                        if (fcnt > maxErrorCount)
                            msg += "<br>" + ew.language.phrase("ImportMessageMore").replace("%s", fcnt - maxErrorCount);
                        else
                            showLog = false;
                    }
                    if (fileResult.log && showLog)
                        msg += "<br>" + ew.language.phrase("ImportMessageError4").replace("%l", fileResult.log);
                    showMessage(msg, "text-danger"); // Show error message
                }
            }
        }
        if (result.success) {
            showMessage(msg, "text-success");
            $dlg.find(".modal-footer .ew-close-btn").data("imported", true);
        } else {
            if (result.error)
                msg = result.error;
            showMessage(msg, "text-danger"); // Show error message
        }
        hideProgress();
    }

    // Import fail
    var importFail = function(o) {
        $dlg.find(".modal-footer .ew-close-btn").data("import-progress", false); // Stop import progress
        showMessage(ew.language.phrase("ImportMessageServerError").replace("%s", o.status).replace("%t", o.statusText), "text-danger");
    }

    // Import file
    var importFiles = function(filetoken) {
        $body.css("cursor", "wait");
        $input.data(ew.API_FILE_TOKEN_NAME, filetoken);
        $dlg.find(".modal-footer .ew-close-btn").data("import-progress", true); // Show import progress
        var data = ew.API_ACTION_NAME + "=import&" + ew.API_FILE_TOKEN_NAME + "=" + encodeURIComponent(filetoken);
        if ($data.length)
            data += "&" + $data.serialize();
        $.ajax(currentPage(), {
            "data": data,
            "method": "POST",
            "dataType": "json",
            "beforeSend": function(xhr, settings) {
                timer = $.later(100, null, importProgress, null, true); // Use time to show progress periodically
            }
        }).done(importComplete).fail(importFail).always(function() {
            $body.css("cursor", "default");
            if (timer)
                timer.cancel(); // Clear timer
        });
    }

    var formData = { session: ew.SESSION_ID };
    formData[ew.TOKEN_NAME_KEY] = ew.TOKEN_NAME; // Add token name for $.ajax() sent by jQuery File Upload (not by ajaxSend) // PHP
    formData[ew.ANTIFORGERY_TOKEN_KEY] = ew.ANTIFORGERY_TOKEN; // Add antiforgery token for $.ajax() sent by jQuery File Upload (not by ajaxSend) // PHP
    var options = ew.importUploadOptions;
    if (!options.acceptFileTypes)
        options.acceptFileTypes = new RegExp('\\.(' + ew.IMPORT_FILE_ALLOWED_EXT.replace(/,/g, '|') + ')$', 'i');

    if (!$input.data("blueimpFileupload")) {
        $input.fileupload(Object.assign({
                url: getApiUrl(ew.API_UPLOAD_ACTION),
                dataType: "json",
                autoUpload: true,
                formData: formData,
                singleFileUploads: false,
                messages: {
                    acceptFileTypes: ew.language.phrase("UploadErrMsgAcceptFileTypes"),
                    maxFileSize: ew.language.phrase("UploadErrMsgMaxFileSize"),
                    maxNumberOfFiles: ew.language.phrase("UploadErrMsgMaxNumberOfFiles"),
                    minFileSize: ew.language.phrase("UploadErrMsgMinFileSize")
                },
                beforeSend: function(jqxhr, settings) {
                    if (ew.API_JWT_TOKEN && !ew.IS_WINDOWS_AUTHENTICATION) // Do NOT set JWT authorization header if Windows Authentication
                        jqxhr.setRequestHeader(ew.API_JWT_AUTHORIZATION_HEADER, "Bearer " + ew.API_JWT_TOKEN);
                },
                done: function(e, data) {
                    if (data.result && data.result.files && Array.isArray(data.result.files.importfiles)) {
                        var ok = true;
                        data.result.files.importfiles.forEach(function(file, index) {
                            if (file.error) {
                                showMessage(ew.language.phrase("ImportMessageUploadError").replace("%f", file.name).replace("%s", file.error), "text-danger");
                                ok = false;
                            }
                        }); // Show upload errors for each file
                        if (ok)
                            importFiles(data.result[ew.API_FILE_TOKEN_NAME]); // Import uploaded files
                    }
                },
                change: function(e, data) {
                    hideMessage();
                },
                processfail: function(e, data) {
                    data.files.forEach(function(file, index) {
                        if (file.error)
                            showMessage(ew.language.phrase("ImportMessageUploadError").replace("%f", file.name).replace("%s", file.error), "text-danger");
                    }); // Show process errors for each file
                },
                fail: function(e, data) {
                    showMessage(ew.language.phrase("ImportMessageServerError").replace("%s", data.textStatus).replace("%t", data.errorThrown), "text-danger");
                },
                progressall: function(e, data) {
                    uploadProgress(data);
                }
        }, options));
    }

    $dlg.modal("hide").find(".modal-title").html(args.hdr);
    $dlg.find(".modal-footer .ew-close-btn").off("click.ew").on("click.ew", function() {
        var $this = $(this);
        if ($this.data("imported")) {
            $this.data("imported", false);
            window.location.reload();
        }
    });
    hideMessage();
    ew.importDialog = $dlg.modal("show");
    return false;
}

// Auto-fill
export function autoFill(el) {
    var f = forms.get(el).$element[0];
    if (!f)
        return;
    var ar = getOptionValues(el),
        id = getId(el),
        m = id.match(/^([xy])(\d*)_/),
        rowindex = (m) ? m[2] : "",
        list = forms.get(el).getList(id),
        dest_array = list.autoFillTargetFields;
    var success = function(data) {
        var results = data && data.records || "";
        var result = (results) ? results[0] : [];
        for (var j = 0; j < dest_array.length; j++) {
            var destEl = getElements(dest_array[j].replace(/^x_/, "x" + rowindex + "_"), f);
            if (destEl) {
                var val = ($.isValue(result["af" + j])) ? String(result["af" + j]) : "";
                var args = {results: results, result: result, data: val, form: f, name: id, target: dest_array[j], cancel: false, trigger: true};
                $(el).trigger("autofill", [args]); // Fire event
                if (args.cancel)
                    continue;
                val = args.data; // Process the value
                if (destEl.options) { // Selection list
                    selectOption(destEl, val.split(","));
                    if (isAutoSuggest(destEl)) { // Auto-Suggest
                        destEl.input.value = val;
                        updateOptions.call(forms.get(f.id), destEl);
                    } else if (isModalLookup(destEl)) { // Modal-Lookup
                        //$(destEl).parent().find(".ew-lookup-text").html(val);
                        updateOptions.call(forms.get(f.id), destEl);
                    }
                } else if (isHiddenTextArea(destEl)) { // HTML editor
                    destEl.value = val;
                    $(destEl).data("editor").set();
                } else {
                    destEl.value = val;
                }
                if (args.trigger)
                    $(destEl).trigger("change");
            }
        }
        return result;
    };
    if (ar.length > 0 && ar[0] != "") {
        var data = Object.assign({
            page: list.page,
            field: list.field,
            ajax: "autofill",
            v0: ar[0],
            language: ew.LANGUAGE_ID
        }, getUserParams('#p_' + id, f));
        // Add parent field values
        var parentId = list.parentFields.slice(); // Clone
        if (rowindex != "") {
            for (var i = 0, len = parentId.length; i < len; i++) {
                var ar = parentId[i].split(" ");
                if (ar.length == 1) // Parent field in the same table, add row index
                    parentId[i] = parentId[i].replace(/^x_/, "x" + rowindex + "_");
            }
        }
        var arp = parentId.map(function(pid) {
            return getOptionValues(pid, f);
        });
        for (var i = 0, cnt = arp.length; i < cnt; i++) // Filter by parent fields
            data["v" + (i + 1)] = arp[i].join(ew.MULTIPLE_OPTION_SEPARATOR);
        return $.post(getApiUrl(ew.API_LOOKUP_ACTION), data, success, "json");
    }
    return success();
}

// Setup tooltip links
export function tooltip(i, el) {
    var $this = $(el), $tt = $("#" + $this.data("tooltip-id")),
        trig = $this.data("trigger") || "hover", dir = $this.data("placement") || (ew.CSS_FLIP ? "left" : "right"); // dir = "left|right"
    if (!$tt[0] || $tt.text().trim() == "" && !$tt.find("img[src!='']")[0])
        return;
    if (!$this.data("bs.popover")) {
        $this.popover({
            html: true,
            placement: dir,
            trigger: trig,
            delay: 100,
            container: $("#ew-tooltip")[0],
            content: $tt.html(),
            sanitizeFn: ew.sanitizeFn
        }).on("show.bs.popover", function(e) {
            var wd, $tip = $($this.data("bs.popover").getTipElement()).css("z-index", 9999); // Make z-index higher than modal dialog
            if (wd = $this.data("tooltip-width")) // Set width before show
                $tip.css("max-width", parseInt(wd, 10) + "px");
        });
    }
}

/**
 * Show dialog for email sending
 *
 * @param {Object} args - Arguments
 * @param {string} args.lnk - Email link ID
 * @param {string} args.hdr - Dialog header
 * @param {string} args.url - URL of the email content
 * @param {HTMLElement} args.f - Form
 * @param {Object} args.key - Key as object
 * @param {boolean} args.sel - Exported selected
 * @returns false
 */
export function emailDialogShow(args) {
    var $dlg = ew.emailDialog || $("#ew-email-dialog");
    if (!$dlg[0]) {
        _alert("DIV #ew-email-dialog not found.");
        return false;
    }
    if (args.sel && !keySelected(args.f)) {
        _alert(ew.language.phrase("NoRecordSelected"));
        return false;
    }
    var $f = $dlg.find(".modal-body form"),
        frm = $f.data("form");
    if (!frm) {
        frm = new Form($f.attr("id"));
        frm.addFields([
            ["sender", [ew.Validators.required(ew.language.phrase("Sender")), ew.Validators.email]],
            ["recipient", [ew.Validators.required(ew.language.phrase("Recipient")), ew.Validators.emails(ew.MAX_EMAIL_RECIPIENT, ew.language.phrase("EnterProperRecipientEmail"))]],
            ["cc", ew.Validators.emails(ew.MAX_EMAIL_RECIPIENT, ew.language.phrase("EnterProperCcEmail"))],
            ["bcc", ew.Validators.emails(ew.MAX_EMAIL_RECIPIENT, ew.language.phrase("EnterProperBccEmail"))],
            ["subject", ew.Validators.required(ew.language.phrase("Subject"))]
        ]);
        frm.validate = function() {
            return this.validateFields();
        };
        frm.submit = function(e) {
            if (!this.validate())
                return false;
            var qs = $f.serialize(), data = "";
            if (args.f && args.sel) // Export selected
                data = $(args.f).find("input[type=checkbox][name='key_m[]']:checked").serialize();
            if (args.key)
                qs += "&" + $.param(args.key);
            var fobj = this.getForm();
            if (args.url) { // Custom Template
                $dlg.modal("hide");
                if (args.exportid)
                    ew.exportWithCharts(args.el, args.url, args.exportid, fobj);
                else
                    _export(args.f, args.url, "email", true, args.sel, fobj);
            } else {
                $.post($(args.f).attr("action"), qs + "&" + data, function(result) {
                    showMessage(result);
                });
            }
            return true;
        };
        $f.data("form", frm);
    }
    $dlg.modal("hide").find(".modal-title").html(args.hdr);
    $dlg.find(".modal-footer .btn-primary").off().click(function(e) {
        e.preventDefault();
        if (frm.submit(e))
            $dlg.modal("hide");
    });
    ew.emailDialog = $dlg.modal("show");
    return false;
}

// Show drill down
export function showDrillDown(e, obj, url, id, hdr) {
    if (e && e.ctrlKey) {
        var arUrl = url.split("?"), params = new URLSearchParams(arUrl[1]);
        params.set("d", "2");  // Change d parameter to 2
        redirect(arUrl[0] + "?" + params.toString());
        return false;
    }
    var $obj = ($.isString(obj)) ? $("#" + obj) : $(obj);
    var pos = $obj.data("drilldown-placement") || ($obj.hasClass("ew-chart-canvas") ? (ew.CSS_FLIP ? "left" : "right") : "bottom");
    $obj.tooltip("hide");
    var args = {"obj": $obj[0], "id": id, "url": url, "hdr": hdr, "placement": pos};
    $document.trigger("drilldown", [args]);
    var ar = args.url.split("?");
    args.file = ar[0] || "";
    args.data = ar[1] || "";
    if (!$obj.data("bs.popover")) {
        $obj.popover({
            html: true,
            placement: args.placement,
            trigger: "manual",
            template: '<div class="popover"><h3 class="popover-header d-none" style="cursor: move;"></h3><div class="popover-body"></div></div>',
            content: '<div class="' + ew.spinnerClass + ' m-3 ew-loading" role="status"><span class="sr-only">' + ew.language.phrase("Loading") + '</span></div>',
            container: $("#ew-drilldown-panel"),
            sanitizeFn: ew.sanitizeFn,
            boundary: "viewport"
        }).on("show.bs.popover", function(e) {
            $obj.attr("data-original-title", "");
        }).on("shown.bs.popover", function(e) {
            if (!$obj.data("args"))
                return;
            var data = $obj.data("args").data;
            data += (data ? "&" : "") + ew.TOKEN_NAME_KEY + "=" + ew.TOKEN_NAME; // Add token name // PHP
            data += (data ? "&" : "") + ew.ANTIFORGERY_TOKEN_KEY + "=" + ew.ANTIFORGERY_TOKEN; // Add antiforgery token // PHP
            $.ajax({
                cache: false,
                dataType: "html",
                type: "POST",
                data: data,
                url: $obj.data("args").file,
                success: function(data) {
                    var $tip = $($obj.data("bs.popover").getTipElement()).on("mousedown", function(e) {
                        var $this = $(this).addClass("drag"),
                            height = $this.outerHeight(),
                            width = $this.outerWidth(),
                            ypos = $this.offset().top + height - e.pageY,
                            xpos = $this.offset().left + width - e.pageX;
                        $body.on("mousemove", function(e) {
                            var top = e.pageY + ypos - height,
                                left = e.pageX + xpos - width;
                            if ($this.hasClass("drag"))
                                $this.offset({top: top, left: left});
                        }).on("mouseup", function(e){
                            $this.removeClass("drag");
                        });
                    });
                    if (args.hdr)
                        $tip.find(".popover-header").empty().removeClass("d-none")
                            .append('<button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + args.hdr)
                            .find(".close").on("click", function() {
                                $obj.popover("hide");
                            });
                    var m = data.match(/<body[^>]*>([\s\S]*?)<\/body\s*>/i); // Use HTML in document body only
                    data = m ? m[0] : data;
                    var html = ew.stripScript(data);
                    $tip.find(".popover-body").html($("<div></div>").html(html).find("#ew-report")) // Insert the container table only
                        .find(".ew-table").each(ew.setupTable);
                    ew.executeScript(data, id);
                    $obj.popover("update");
                },
                error: function(o) {
                    if (o.responseText) {
                        var $tip = $($el.data("bs.popover").getTipElement());
                        $tip.find(".popover-body").empty().append('<p class="text-danger">' + o.responseText + '</p>');
                    }
                }
            });
        }).on("hidden.bs.popover", function(e) {
            $.each(ew.drillDownCharts, function(key, cht) { // Dispose charts
                cht.dispose();
            });
            ew.drillDownCharts = {};
            ew.removeScript(id);
        });
    }
    $obj.data("args", args).popover("show");
}

/**
 * Ajax query
 * @param {Object} data - object to passed to API
 * @param {callback} callback - Callback function for async request (see http://api.jquery.com/jQuery.post/), empty for sync request
 * @returns {string|string[]}
 */
export function ajax(data, callback) {
    if (!$.isObject(data) || !data.url && !data.action)
        return undefined;
    var action;
    if (data.url) {
        if (data.url.startsWith(getApiUrl()))
            action = data.url.replace(getApiUrl(), "").split("/")[0];
        else if (data.url.startsWith(ew.API_URL))
            action = data.url.replace(ew.API_URL, "").split("/")[0];
    } else { 
        action = data.action
        delete data.action;
    }
    var obj = Object.assign({}, data);
    var _convert = (response) => {
        if ($.isObject(response) && response.result == "OK") {
            var results = response.records;
            if (Array.isArray(results) && results.length == 1) { // Single row
                results = results[0];
                if (Array.isArray(results) && results.length == 1) // Single column
                    return results[0]; // Return a value
                else
                    return results; // Return a row
            }
            return results;
        }
        return response;
    };
    var url = obj.url || getApiUrl(action), // URL
        type = obj.type || ([ew.API_LIST_ACTION, ew.API_VIEW_ACTION, ew.API_DELETE_ACTION].includes(action) ? "GET" : "POST");
    delete obj.url;
    delete obj.type;
    obj.dataType = "json";
    if (isFunction(callback)) { // Async
        $.ajax({
            url: url,
            type: type,
            data: obj,
            success: function (response) {
                callback(_convert(response));
            }
        });
    } else { // Sync
        var response = $.ajax({
            url: url,
            async: false,
            type: type,
            data: obj
        });
        return _convert(response.responseJSON);
    }
}

// Get URL of current page
export function currentPage() {
    return location.href.split("#")[0].split("?")[0];
}

// Toggle search operator
export function toggleSearchOperator(id, value) {
    var el = this.form.elements[id];
    if (!el)
        return;
    el.value = (el.value != value) ? value : "=";
}

// Validators
// Check US Date format (mm/dd/yyyy)
export function checkUSDate(object_value) {
    return checkDateEx(object_value, "us", ew.DATE_SEPARATOR);
}

// Check US Date format (mm/dd/yy)
export function checkShortUSDate(object_value) {
    return checkDateEx(object_value, "usshort", ew.DATE_SEPARATOR);
}

// Check Date format (yyyy/mm/dd)
export function checkDate(object_value) {
    return checkDateEx(object_value, "std", ew.DATE_SEPARATOR);
}

// Check Date format (yy/mm/dd)
export function checkShortDate(object_value) {
    return checkDateEx(object_value, "stdshort", ew.DATE_SEPARATOR);
}

// Check Euro Date format (dd/mm/yyyy)
export function checkEuroDate(object_value) {
    return checkDateEx(object_value, "euro", ew.DATE_SEPARATOR);
}

// Check Euro Date format (dd/mm/yy)
export function checkShortEuroDate(object_value) {
    return checkDateEx(object_value, "euroshort", ew.DATE_SEPARATOR);
}

// Check default date format
export function checkDateDef(object_value) {
    if (ew.DATE_FORMAT.match(/^yyyy/))
        return checkDate(object_value);
    else if (ew.DATE_FORMAT.match(/^yy/))
        return checkShortDate(object_value);
    else if (ew.DATE_FORMAT.match(/^m/) && ew.DATE_FORMAT.match(/yyyy$/))
        return checkUSDate(object_value);
    else if (ew.DATE_FORMAT.match(/^m/) && ew.DATE_FORMAT.match(/yy$/))
        return checkShortUSDate(object_value);
    else if (ew.DATE_FORMAT.match(/^d/) && ew.DATE_FORMAT.match(/yyyy$/))
        return checkEuroDate(object_value);
    else if (ew.DATE_FORMAT.match(/^d/) && ew.DATE_FORMAT.match(/yy$/))
        return checkShortEuroDate(object_value);
    return false;
}

// Check date format
// format: std/stdshort/us/usshort/euro/euroshort
export function checkDateEx(value, format, sep) {
    if (!value || value.length == "")
        return true;
    value = value.replace(/ +/g, " ").trim();
    var arDT = value.split(" ");
    if (arDT.length > 0) {
        var re, ar, sYear, sMonth, sDay;
        re = /^(\d{4})-([0][1-9]|[1][0-2])-([0][1-9]|[1|2]\d|[3][0|1])$/;
        if (ar = re.exec(arDT[0])) {
            sYear = ar[1];
            sMonth = ar[2];
            sDay = ar[3];
        } else {
            var wrksep = escapeRegExChars(sep);
            switch (format) {
                case "std":
                    re = new RegExp("^(\\d{4})" + wrksep + "([0]?[1-9]|[1][0-2])" + wrksep + "([0]?[1-9]|[1|2]\\d|[3][0|1])$");
                    break;
                case "stdshort":
                    re = new RegExp("^(\\d{2})" + wrksep + "([0]?[1-9]|[1][0-2])" + wrksep + "([0]?[1-9]|[1|2]\\d|[3][0|1])$");
                    break;
                case "us":
                    re = new RegExp("^([0]?[1-9]|[1][0-2])" + wrksep + "([0]?[1-9]|[1|2]\\d|[3][0|1])" + wrksep + "(\\d{4})$");
                    break;
                case "usshort":
                    re = new RegExp("^([0]?[1-9]|[1][0-2])" + wrksep + "([0]?[1-9]|[1|2]\\d|[3][0|1])" + wrksep + "(\\d{2})$");
                    break;
                case "euro":
                    re = new RegExp("^([0]?[1-9]|[1|2]\\d|[3][0|1])" + wrksep + "([0]?[1-9]|[1][0-2])" + wrksep + "(\\d{4})$");
                    break;
                case "euroshort":
                    re = new RegExp("^([0]?[1-9]|[1|2]\\d|[3][0|1])" + wrksep + "([0]?[1-9]|[1][0-2])" + wrksep + "(\\d{2})$");
                    break;
            }
            if (!re.test(arDT[0]))
                return false;
            var arD = arDT[0].split(sep);
            switch (format) {
                case "std":
                case "stdshort":
                    sYear = unformatYear(arD[0]);
                    sMonth = arD[1];
                    sDay = arD[2];
                    break;
                case "us":
                case "usshort":
                    sYear = unformatYear(arD[2]);
                    sMonth = arD[0];
                    sDay = arD[1];
                    break;
                case "euro":
                case "euroshort":
                    sYear = unformatYear(arD[2]);
                    sMonth = arD[1];
                    sDay = arD[0];
                    break;
            }
        }
        if (!checkDay(sYear, sMonth, sDay))
            return false;
    }
    if (arDT.length > 1 && !checkTime(arDT[1]))
        return false;
    return true;
}

// Unformat 2 digit year to 4 digit year
export function unformatYear(yr) {
    if (yr.length == 2)
        return (yr > ew.UNFORMAT_YEAR) ? "19" + yr : "20" + yr;
    return yr;
}

// Check day
export function checkDay(checkYear, checkMonth, checkDay) {
    checkYear = parseInt(checkYear, 10);
    checkMonth = parseInt(checkMonth, 10);
    checkDay = parseInt(checkDay, 10);
    var maxDay = [4, 6, 9, 11].includes(checkMonth) ? 30 : 31;
    if (checkMonth == 2)
        maxDay = (checkYear % 4 > 0 || checkYear % 100 == 0 && checkYear % 400 > 0) ? 28 : 29;
    return checkRange(checkDay, 1, maxDay);
}

// Check integer
export function checkInteger(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    if (object_value.includes(ew.DECIMAL_POINT))
        return false;
    return checkNumber(object_value);
}

// Check number
export function checkNumber(object_value) {
    object_value = String(object_value);
    if (!object_value || object_value.length == 0)
        return true;
    object_value = object_value.trim();
    var ts = escapeRegExChars(ew.THOUSANDS_SEP), dp = escapeRegExChars(ew.DECIMAL_POINT),
        re = new RegExp("^[+-]?(\\d{1,3}(" + (ts ? ts + "?" : "") + "\\d{3})*(" + dp + "\\d+)?|" + dp + "\\d+)$");
    return re.test(object_value);
}

// Convert to float
export function stringToFloat(object_value) {
    object_value = String(object_value);
    if (ew.THOUSANDS_SEP != "") {
        var re = new RegExp(escapeRegExChars(ew.THOUSANDS_SEP), "g");
        object_value = object_value.replace(re, "");
    }
    if (ew.DECIMAL_POINT != "")
        object_value = object_value.replace(ew.DECIMAL_POINT, ".");
    return parseFloat(object_value);
}

// Convert string (yyyy-mm-dd hh:mm:ss) to date object
export function stringToDate(object_value) {
    var re = /^(\d{4})-([0][1-9]|[1][0-2])-([0][1-9]|[1|2]\d|[3][0|1]) (?:(0\d|1\d|2[0-3]):([0-5]\d):([0-5]\d))?$/;
    var ar = object_value.replace(re, "$1 $2 $3 $4 $5 $6").split(" ");
    return new Date(ar[0], ar[1]-1, ar[2], ar[3], ar[4], ar[5]);
}

// Escape regular expression chars
export function escapeRegExChars(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

// Check range
export function checkRange(object_value, min_value, max_value) {
    if (!object_value || object_value.length == 0)
        return true;
    if ($.isNumber(min_value) || $.isNumber(max_value)) { // Number
        if (checkNumber(object_value))
            object_value = stringToFloat(object_value);
    }
    if (!$.isNull(min_value) && object_value < min_value)
        return false;
    if (!$.isNull(max_value) && object_value > max_value)
        return false;
    return true;
}

// Check time
export function checkTime(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    object_value = object_value.trim();
    var re = new RegExp('^(0\\d|1\\d|2[0-3])' + escapeRegExChars(ew.TIME_SEPARATOR) + '[0-5]\\d(( (' + escapeRegExChars(ew.language.phrase("AM")) + '|' + escapeRegExChars(ew.language.phrase("PM")) + '))|(' + escapeRegExChars(ew.TIME_SEPARATOR) + '[0-5]\\d(\\.\\d+|[+-][\\d:]+)?)?)$', 'i');
    return re.test(object_value);
}

// Check phone
export function checkPhone(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    return /^\(\d{3}\) ?\d{3}( |-)?\d{4}|^\d{3}( |-)?\d{3}( |-)?\d{4}$/.test(object_value.trim());
}

// Check zip
export function checkZip(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    return /^\d{5}$|^\d{5}-\d{4}$/.test(object_value.trim());
}

// Check credit card
export function checkCreditCard(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    var creditcard_string = object_value.replace(/\D/g, "");
    if (creditcard_string.length == 0)
        return false;
    var doubledigit = creditcard_string.length % 2 == 1 ? false : true;
    var tempdigit, checkdigit = 0;
    for (var i = 0, len = creditcard_string.length; i < len; i++) {
        tempdigit = parseInt(creditcard_string.charAt(i), 10);
        if (doubledigit) {
            tempdigit *= 2;
            checkdigit += (tempdigit % 10);
            if (tempdigit / 10 >= 1.0)
                checkdigit++;
            doubledigit = false;
        }	else {
            checkdigit += tempdigit;
            doubledigit = true;
        }
    }
    return (checkdigit % 10 == 0);
}

// Check social security number
export function checkSsn(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    return /^(?!000)([0-6]\d{2}|7([0-6]\d|7[012]))([ -]?)(?!00)\d\d\3(?!0000)\d{4}$/.test(object_value.trim());
}

// Check emails
export function checkEmails(object_value, email_cnt) {
    if (!object_value || object_value.length == 0)
        return true;
    var arEmails = object_value.replace(/,/g, ";").split(";");
    for (var i = 0, len = arEmails.length; i < len; i++) {
        if (email_cnt > 0 && len > email_cnt)
            return false;
        if (!checkEmail(arEmails[i]))
            return false;
    }
    return true;
}

// Check email
export function checkEmail(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    return /^[\w.%+-]+@[\w.-]+\.[A-Z]{2,18}$/i.test(object_value.trim());
}

// Check GUID {xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx}
export function checkGuid(object_value) {
    if (!object_value || object_value.length == 0)
        return true;
    return /^(\{\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\}|\w{8}-\w{4}-\w{4}-\w{4}-\w{12})$/.test(object_value.trim());
}

// Check by regular expression
export function checkByRegEx(object_value, pattern) {
    if (!object_value || object_value.length == 0)
        return true;
    return !!object_value.match(pattern);
}

/**
 * Show message dialog
 *
 * @param {Event|string} arg - Event or message
 * @returns
 */
export function showMessage(arg) {
    var win = window.parent, // Note: If a window does not have a parent, its parent property is a reference to itself.
        p = arg?.target ?? win.document,
        swal = win.Swal,
        $div = $(p).find("div.ew-message-dialog:hidden").first(),
        msg = $div.length ? $div.text() : ""; // Text only
    if ($.isString(arg))
        msg = $("<div>" + arg.trim() + "</div>").text();
    if (msg.trim() == "")
        return;
    if ($div.length) {
        ["success", "info", "warning", "danger"].forEach(function(value, index) {
            var $alert = $div.find(".alert-" + value).toggleClass("alert-" + value),
                $heading = $alert.find(".alert-heading").detach(),
                $content = $alert.children(":not(.icon)");
            $alert.find(".icon").remove();
            if ($alert[0]) {
                var w = parseInt($content.css("width"), 10); // Width specified
                if (w > 0)
                    $content.first().css("width", "auto");
                var $toast = toast({
                    class: "ew-toast bg-" + value,
                    title: $heading[0] ? $heading.html() : ew.language.phrase(value),
                    body: $alert.html(),
                    autohide: (value == "success") ? ew.autoHideSuccessMessage : false, // Autohide for success message
                    delay: (value == "success") ? ew.autoHideSuccessMessageDelay: 500
                });
                if (w > 0)
                    $toast.css("max-width", w); // Override bootstrap .toast max-width
                return;
            }
        });
    }
    if ($.isString(arg)) {
        return swal.fire({
            ...ew.sweetAlertSettings,
            html: arg
        });
    }
}

// Random number
export function random() {
    return Math.floor(Math.random() * 100001) + 100000;
}

// File upload
export function upload(input) {
    var $input = $(input);
    if ($input.data("blueimpFileupload"))
        return;
    var id = $input.attr("name"), nid = id.replace(/\$/g, "\\$"), tbl = $input.data("table"),
        multiple = $input.is("[multiple]"), $p = $input.closest(".form-group, [id^='el']"),
        readonly = $input.prop("disabled") || $input.closest("form").find("#confirm").val() == "confirm",
        $ft = $p.find("#ft_" + nid), $fn = $p.find("#fn_" + nid), $fa = $p.find("#fa_" + nid), $fs = $p.find("#fs_" + nid),
        $exts = $p.find("#fx_" + nid), $maxsize = $p.find("#fm_" + nid), $maxfilecount = $p.find("#fc_" + nid),
        $label = $p.find(".custom-file-label"), label = $label.html();
    var _done = function(e, data) {
        if (data.result.files[0].error)
            return;
        var name = data.result.files[0].name;
        var ar = (multiple) ? ($fn.val() ? $fn.val().split(ew.MULTIPLE_UPLOAD_SEPARATOR) : []) : [];
        ar.push(name);
        $fn.val(ar.join(ew.MULTIPLE_UPLOAD_SEPARATOR));
        $fa.val("0");
        if (!multiple) // Remove other entries if not multiple upload
            $ft.children("tr:not(:last-child)").remove();
    };
    var _deleted = function(e, data) {
        var url = $(e.originalEvent.target).data("url"),
            param = new URLSearchParams(url.split("?")[1]),
            fid = param.get("id"),
            name = param.get(fid);
        if (name) {
            var ar = $fn.val() ? $fn.val().split(ew.MULTIPLE_UPLOAD_SEPARATOR) : [];
            var index = ar.indexOf(name);
            if (index > -1)
                ar.splice(index, 1);
            $fn.val(ar.join(ew.MULTIPLE_UPLOAD_SEPARATOR));
            $fa.val("0");
        }
    };
    var _change = function(e, data) {
        $ft.toggleClass("ew-has-rows", data.files?.length > 0);
        var ar = $fn.val() ? $fn.val().split(ew.MULTIPLE_UPLOAD_SEPARATOR) : [];
        for (var i = 0; i < data.files.length; i++)
            ar.push(data.files[i].name);
        var cnt = parseInt($maxfilecount.val(), 10);
        if ($.isNumber(cnt) && cnt > 0 && ar.length > cnt) {
            _alert(ew.language.phrase("UploadErrMsgMaxNumberOfFiles"));
            return false;
        }
        var l = parseInt($fs.val(), 10);
        if ($.isNumber(l) && l > 0 && ar.join(ew.MULTIPLE_UPLOAD_SEPARATOR).length > l) {
            _alert(ew.language.phrase("UploadErrMsgMaxFileLength"));
            return false;
        }
    };
    var _confirmDelete = function(e) {
        if (!multiple && $fn.val()) {
            if (!confirm(ew.language.phrase("UploadOverwrite"))) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    };
    var _changed = function(e, data) {
        $ft.toggleClass("ew-has-rows", data.files?.length > 0 || data.result?.files?.length > 0);
        var ar = $fn.val() ? $fn.val().split(ew.MULTIPLE_UPLOAD_SEPARATOR) : [];
        $label.html(ar.join(", ") || label);
    };
    var _clicked = function() {
        $input.closest("span.fileinput-button").tooltip("hide");
    };
    // var _process = function(e, data) {
    //     $ft.toggleClass("ew-has-rows", data.files?.length > 0);
    // };
    var _downloadTemplate = $.templates("#template-download");
    var _uploadTemplate = $.templates("#template-upload");
    var _completed = function(e, data) { // After download template rendered
        var e = { target: data.context };
        initLightboxes(e);
        initPdfObjects(e);
        ew.updateDropdownPosition();
        data.context.find("img").on("load", ew.updateDropdownPosition);
    }
    var _added = function(e, data) { // After upload template rendered
        $ft.toggleClass("ew-has-rows", data.files?.length > 0);
        data.context.find(".start").click(_confirmDelete);
    };
    // Hide input button if readonly
    var form = getForm(input), $form = $(form);
    var readonly = $form.find("#confirm").val() == "confirm";
    if (readonly)
        $form.find("span.fileinput-button").hide();
    var cnt = parseInt($maxfilecount.val(), 10);
    var uploadUrl = getApiUrl(ew.API_JQUERY_UPLOAD_ACTION);
    var formData = {
        id: id,
        table: tbl,
        session: ew.SESSION_ID,
        replace: (multiple ? "0" : "1"),
        exts: $exts.val(),
        maxsize: $maxsize.val(),
        maxfilecount: $maxfilecount.val()
    };
    $input.fileupload({
        url: uploadUrl,
        type: "POST",
        multipart: true,
        autoUpload: true, // Comment out to disable auto upload
        loadImageFileTypes: /^image\/(gif|jpe?g|png)$/i,
        loadVideoFileTypes: /^video\/mp4$/i,
        loadAudioFileTypes: /^audio\/(mpeg|mp3)$/i,
        acceptFileTypes: ($exts.val()) ? new RegExp('\\.(' + $exts.val().replace(/,/g, '|') + ')$', 'i') : null,
        maxFileSize: parseInt($maxsize.val(), 10),
        maxNumberOfFiles: (cnt > 1) ? cnt : null,
        filesContainer: $ft,
        formData: formData,
        uploadTemplateId: null,
        downloadTemplateId: null,
        uploadTemplate: _uploadTemplate.render.bind(_uploadTemplate),
        downloadTemplate: _downloadTemplate.render.bind(_downloadTemplate),
        previewMaxWidth: ew.UPLOAD_THUMBNAIL_WIDTH,
        previewMaxHeight: ew.UPLOAD_THUMBNAIL_HEIGHT,
        dropZone: $p,
        pasteZone: $p,
        messages: {
            acceptFileTypes: ew.language.phrase("UploadErrMsgAcceptFileTypes"),
            maxFileSize: ew.language.phrase("UploadErrMsgMaxFileSize"),
            maxNumberOfFiles: ew.language.phrase("UploadErrMsgMaxNumberOfFiles"),
            minFileSize: ew.language.phrase("UploadErrMsgMinFileSize")
        },
        readOnly: readonly // Custom
    }).on("fileuploaddone", _done)
        .on("fileuploaddestroy", _deleted)
        .on("fileuploadchange", _change)
        .on("fileuploadadded fileuploadfinished fileuploaddestroyed", _changed)
        //.on("fileuploadprocess", _process)
        .on('fileuploadadded', _added)
        .on('fileuploadcompleted', _completed)
        .click(_clicked);
    if ($fn.val()) {
        $.ajax({
            url: uploadUrl,
            data: { id: id, table: tbl, session: ew.SESSION_ID },
            dataType: "json",
            context: this,
            success: function(result) {
                if (result && result[id]) {
                    var done = $input.fileupload("option", "done");
                    if (done)
                        done.call(input, $.Event(), { result: { files: result[id] } }); // Use "files"
                }
                if (readonly) // Hide delete button if readonly
                    $ft.find("td.delete").hide();
            }
        });
    }
}

/**
 * Convert data to number
 *
 * @param {*} data - Data being converted
 * @param {Object} [config] - Configuration
 * @param {string} config.decimalSeparator - Decimal separator
 * @param {string} config.thousandsSeparator - Thousands separator
 * @returns {(number|null)}
 */
export function parseNumber(data, config) {
    if ($.isString(data)) {
        config = config || {"thousandsSeparator": ew.THOUSANDS_SEP, "decimalSeparator": ew.DECIMAL_POINT};
        var regexBits = [], regex, separator = config.thousandsSeparator, decimal = config.decimalSeparator;
        if (separator)
            regexBits.push(escapeRegExChars(separator) + "(?=\\d)");
        regex = new RegExp("(?:" + regexBits.join("|") + ")", "g");
        if (decimal === ".")
            decimal = null;
        data = data.replace(regex, "");
        data = (decimal) ? data.replace(decimal, ".") : data;
    }
    if ($.isString(data) && data.trim() !== "")
        data = +data;
    if (!$.isNumber || !isFinite(data)) // Catch NaN and Infinity
        data = null;
    return data;
}

/**
 * Format a Number to string for display
 *
 * @param {*} data - Data being converted
 * @param {Object} [config] - Configuration
 * @param {number} config.decimalPlaces - Number of decimal places to round. Must be a number 0 to 20.
 * @param {string} config.decimalSeparator - Decimal separator
 * @param {string} config.thousandsSeparator - Thousands separator
 * @returns {string} Note: null, undefined, NaN and "" returns as "".
 */
export function formatNumber(data, config) {
    if ($.isNumber(data)) {
        config = config || {"thousandsSeparator": ew.THOUSANDS_SEP, "decimalSeparator": ew.DECIMAL_POINT};
        var isNeg = (data < 0), output = data + "", decPlaces = config.decimalPlaces,
            decSep = config.decimalSeparator || ".", thouSep = config.thousandsSeparator,
            decIndex, newOutput, count, i;
        if ($.isNumber(decPlaces) && (decPlaces >= 0) && (decPlaces <= 20)) // Decimal precision
            output = data.toFixed(decPlaces);
        if (decSep !== ".") // Decimal separator
            output = output.replace(".", decSep);
        if (thouSep) { // Add the thousands separator
            decIndex = output.lastIndexOf(decSep); // Find the dot or where it would be
            decIndex = (decIndex > -1) ? decIndex : output.length;
            newOutput = output.substring(decIndex); // Start with the dot and everything to the right
            for (count = 0, i = decIndex; i > 0; i--) { // Working left, every third time add a separator, every time add a digit
                if (count%3 === 0 && i !== decIndex && (!isNeg || i > 1))
                    newOutput = thouSep + newOutput;
                newOutput = output.charAt(i-1) + newOutput;
                count++;
            }
            output = newOutput;
        }
        return output;
    } else { // Not a Number, return as string
        return ($.isValue(data) && data.toString) ? data.toString() : "";
    }
}

/**
 * Convert data to Moment object (see http://momentjs.com/docs/)
 *
 * @param {*} data - Data being converted
 * @param {number} format - Date format matching server side FormatDateTime()
 * @returns {Moment}
 */
export function parseDate(data, format) {
    var args = $.makeArray(arguments);
    if ($.isNumber(format) && format >=0 && format <= 17) {
        var f, def = ew.DATE_FORMAT.toUpperCase(), sep = ew.DATE_SEPARATOR, timesep = ew.TIME_SEPARATOR;
        switch (format) {
            case 0: case 1: case 2: case 8: f = def + " HH" + timesep + "mm" + timesep + "ss"; break; // ew.DATE_FORMAT + " %H:%M:%S"
            case 3: f = "hh:mm:ss A"; break; // "%I:%M:%S %p"
            case 4: f = "HH:mm:ss"; break; // "%H:%M:%S"
            case 5: f = "YYYY" + sep + "MM" + sep + "DD"; break; // "%Y" + sep + "%m" + sep + "%d"
            case 6: f = "MM" + sep + "DD" + sep + "YYYY"; break; // "%m" + sep + "%d" + sep + "%Y"
            case 7: f = "DD" + sep + "MM" + sep + "YYYY"; break; // "%d" + sep + "%m" + sep + "%Y"
            case 9: f = "YYYY" + sep + "MM" + sep + "DD HH" + timesep + "mm" + timesep + "ss"; break; // "%Y" + sep + "%m" + sep + "%d %H:%M:%S"
            case 10: f = "MM" + sep + "DD" + sep + "YYYY HH" + timesep + "mm" + timesep + "ss"; break; // "%m" + sep + "%d" + sep + "%Y %H:%M:%S"
            case 11: f = "DD" + sep + "MM" + sep + "YYYY HH" + timesep + "mm" + timesep + "ss"; break; // "%d" + sep + "%m" + sep + "%Y %H:%M:%S"
            case 12: f = "YY" + sep + "MM" + sep + "DD"; break; // "%y" + sep + "%m" + sep + "%d"
            case 13: f = "MM" + sep + "DD" + sep + "YY"; break; // "%m" + sep + "%d" + sep + "%y"
            case 14: f = "DD" + sep + "MM" + sep + "YY"; break; // "%d" + sep + "%m" + sep + "%y"
            case 15: f = "YY" + sep + "MM" + sep + "DD HH" + timesep + "mm" + timesep + "ss"; break; // "%y" + sep + "%m" + sep + "%d %H:%M:%S"
            case 16: f = "MM" + sep + "DD" + sep + "YY HH" + timesep + "mm" + timesep + "ss"; break; // "%m" + sep + "%d" + sep + "%y %H:%M:%S"
            case 17: f = "DD" + sep + "MM" + sep + "YY HH" + timesep + "mm" + timesep + "ss"; break; // "%d" + sep + "%m" + sep + "%y %H:%M:%S"
        }
        args[1] = [f, "YYYY-MM-DD HH" + timesep + "mm" + timesep + "ss"];
    }
    return moment.apply(this, args);
}

/**
 * Format date time
 *
 * @param {*} data - Date being formatted
 * @param {string} format - Date format (see http://momentjs.com/docs/#/displaying/format/)
 * @returns {string}
 */
export function formatDate(data, format) {
    return moment(data).format(format || ew.DATE_FORMAT.toUpperCase());
}

/**
 * Init page
 *
 * @param {Event|undefined} e - Event
 */
export function initPage(e) {
    var el = (e && e.target) ? e.target : document,
        $el = $(el),
        $tables = $el.find("table.ew-table:not(.ew-export-table)");
    Array.prototype.forEach.call(el.querySelectorAll(".ew-grid-upper-panel, .ew-grid-lower-panel"), ew.initGridPanel); // Init grid panels
    ew.renderJsTemplates(e);
    lazyLoad(e);
    initForms(e);
    initTooltips(e);
    initPasswordOptions(e);
    initIcons(e);
    initLightboxes(e);
    initPdfObjects(e);
    $el.find("[data-widget='treeview']").each(function() {
        adminlte.Treeview._jQueryInterface.call($(this), "init");
    });
    $tables.each(setupTable); // Init tables
    $el.find(".ew-btn-dropdown").on("shown.bs.dropdown", function() {
        var $this = $(this).removeClass("dropup"),
            $window = $(window),
            $menu = $this.find("> .dropdown-menu");
        $this.toggleClass("dropup", $menu.offset().top + $menu.height() > $window.scrollTop() + $window.height());
    });
    $el.find("input[name=pageno]").on("keydown", function(e) {
        if (e.key == "Enter") {
            currentUrl.searchParams.set(this.name, parseInt(this.value));
            window.location = sanitizeUrl(currentUrl.toString());
            return false;
        }
    });
    if (!ew.IS_SCREEN_SM_MIN) {
        $el.find("." + ew.RESPONSIVE_TABLE_CLASS + " [data-toggle='dropdown']").parent().on("shown.bs.dropdown", function() {
            var $this = $(this),
                $menu = $this.find(".dropdown-menu"),
                div = $this.closest("." + ew.RESPONSIVE_TABLE_CLASS)[0];
            if (div.scrollHeight - div.clientHeight) {
                var d = $menu.offset().top + $menu.outerHeight() - $(div).offset().top - div.clientHeight;
                if (d > 0)
                    $menu.css(ew.CSS_FLIP ? "right" : "left", "100%").css("top", parseFloat($menu.css("top")) - d);
            }
        });
    }
    initExportLinks(e);
    initMultiSelectCheckboxes(e);

    // Report
    var $rpt = $el.find(".ew-report");
    if ($rpt[0]) {
        $rpt.find(".card").on("collapsed.lte.widget", function() { // Fix min-height when .lte.widget is collapsed
            var $card = $(this), $div = $card.closest("[class^='col-']"), mh = $div.css("min-height");
            if (mh)
                $div.data("min-height", mh);
            $div.css("min-height", 0);
        }).on("expanded.lte.widget", function() { // Fix min-height when .lte.widget is expanded
            var $card = $(this), $div = $card.closest("[class^='col-']"), mh = $div.css("min-height");
            if (mh)
                $div.css("min-height", mh); // Restore min-height
        });
        // Group expand/collapse button
        $rpt.find("span.ew-group-toggle").on("click", function() {
            ew.toggleGroup(this);
        });
    }

    // Show message
    if (typeof ew.USE_JAVASCRIPT_MESSAGE != "undefined" && ew.USE_JAVASCRIPT_MESSAGE)
        showMessage(e);
}

// Redirect by HTTP GET or POST
export function redirect(url, f, method) {
    var newUrl = new URL(url),
        params = newUrl.searchParams;
    params.set(ew.TOKEN_NAME_KEY, ew.TOKEN_NAME); // PHP
    params.set(ew.ANTIFORGERY_TOKEN_KEY, ew.ANTIFORGERY_TOKEN); // PHP
    if (sameText(method, "post")) { // POST
        var $form = (f) ? $(f) : $("<form></form>").appendTo("body");
        $form.attr({ action: ar[0], method: "post" });
        params.forEach(function(value, key) {
            $('<input type="hidden">').attr({ name: key, value: ew.sanitize(value) }).appendTo($form);
        });
        $form.trigger("submit");
    } else { // GET
        window.location = sanitizeUrl(newUrl.toString());
    }
}

// Show/Hide password
export function togglePassword(e) {
    var $btn = $(e.currentTarget), $input = $btn.closest(".input-group").find("input"), $i = $btn.find("i");
    if ($input.attr("type") == "text") {
        $input.attr("type", "password");
        $i.toggleClass("fa-eye-slash fa-eye");
    } else if($input.attr("type") == "password"){
        $input.attr("type", "text");
        $i.toggleClass("fa-eye-slash fa-eye");
    }
}

// Export with charts
export function exportWithCharts(e, url, exportId, f) {
    var el = e.target,
        exportUrl = new URL(window.location.href),
        ar = url.split("?"),
        $el = $(el), method = (f) ? "post" : "get";

    exportId += "_" + Date.now();
    exportUrl.pathname = ar[0];
    exportUrl.search = ar[1];
    exportUrl.searchParams.set("exportid", exportId);

    if ($el.is(".dropdown-menu a"))
        $el = $el.closest(".btn-group");

    var _export = function() {
        var params = exportUrl.searchParams,
            custom = params.get("custom") == "1";
        if (f && !custom) { // Not custom
            var data = $(f).serialize(); // Add token
            $.post(exportUrl, data, function(result) {
                showMessage(result);
            });
        } else { // Custom
            var exp = params.get("export");
            if (custom && ["word", "excel", "pdf", "email"].includes(exp)) {
                if (exp == "email") {
                    params.delete("export"); // Remove duplicate export=email (exists in form)
                    exportUrl.search = params.toString() + "&" + $(f).serialize();
                }
                $("iframe.ew-export").remove();
                $("<iframe></iframe>").addClass("ew-export d-none").attr("src", exportUrl.toString()).appendTo($body.css("cursor", "wait"));
                setTimeout(function() { $body.css("cursor", "default"); }, 5000);
            } else if (exp == "print") {
                redirect(exportUrl.toString(), f, method);
            } else {
                fileDownload(exportUrl.toString(), null);
            }
        }
        return false;
    };

    var keys = Object.keys(window.exportCharts);
    if (keys.length == 0) // No charts, just submit the form
        return _export();

    // Success callback
    var success = function(result) {
        if ($.isString(result))
            result = parseJson(result);
        if (result.success) {
            _export();
        } else {
            ew.alert(result.error);
        }
    };

    // Failure callback
    var fail = function(xhr, status, error) {
        ew.alert(error + ": " + xhr.responseText); // Show detailed export error message
    };

    // Export charts
    $body.css("cursor", "wait");
    var charts = [];
    for (var i = 0; i < keys.length; i++) {
        var id = keys[i], o = window.exportCharts[id],
            params = "exportfilename=" + exportId + "_" + id + ".png|exportformat=png|exportaction=download|exportparameters=undefined";
        if (o && o.toBase64Image) // Chart.js chart
            charts.push({ "chart_engine": "Chart.js", "stream_type": "base64", "stream": o.toBase64Image(), "parameters": params });
    }
    $.ajax({
        "url": getApiUrl(ew.API_EXPORT_CHART_ACTION),
        "data": { "charts": JSON.stringify(charts) },
        "cache": false,
        "type": "POST"
    }).done(success).fail(fail).always(function() {
        $body.css("cursor", "default");
    });
    return false;
}

// Layout
var _fixLayoutHeightTimer;

// Fix layout height
export function fixLayoutHeight() {
    if (_fixLayoutHeightTimer)
        _fixLayoutHeightTimer.cancel(); // Clear timer
    _fixLayoutHeightTimer = $.later(50, null, function () {
        var layout = $body.data("lte.layout");
        if (layout)
            layout.fixLayoutHeight();
    });
}

// Add user event handlers
export function addEventHandlers(tblVar) {
    let fields = ew.events[tblVar];
    if (fields) {
        for (var [fldVar, events] of Object.entries(fields))
            $('[data-table=' + tblVar + '][data-field=' + fldVar + ']').on(events);
    }
}